<template>
  <div>
    <div class="warpper box">
      <h4>{{$t("support.fundInfo.title")}}</h4>
      <img src="@/assets/image/support/fund-info.png">
      <p>{{$t("support.fundInfo.describe")}}</p>
      <button class="btn btn-primary"  @click="$open('signUp', $i18n.locale)">{{$t("support.registerNow")}}</button>
      <div class="gold-list">
        <div class="list" v-for="(item, index) in gold" :key="index">
          <div class="name">{{item.name}}</div>
          <div class="des">{{item.des}}</div>
          <div class="num">{{item.num}}{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fund-info-phone',
  data(){
    return {

    }
  },
  computed: {
    gold() {
      return [
        {
          name: 'USDT',
          des: this.$t('support.fundInfo.golden'),
          num: '10'
        },
        // {
        //   name: 'AUD',
        //   des: this.$t('support.fundInfo.golden'),
        //   num: '200'
        // }, {
        //   name: 'EUR',
        //   des: this.$t('support.fundInfo.golden'),
        //   num: '300'
        // }, {
        //   name: 'GBP',
        //   des: this.$t('support.fundInfo.golden'),
        //   num: '400'
        // }, {
        //   name: 'NZD',
        //   des: this.$t('support.fundInfo.golden'),
        //   num: '500'
        // }
      ]
    }
  }
}
</script>
<style scoped lang="scss">
  .box{
    width: 100vw;
    height: auto;
    padding: 20px 15px;
    text-align: center;
    h4{
      width: 100%;
      height: 22.5px;
      line-height: 22.5px;
      text-align: center;
      color: #000000;
      font-size: 20px;
      font-weight: 600;
    }
    p {
      width: 100%;
      height: auto;
      font-size: 13px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      line-height: 20px;
      margin-top: 10px;
    }
    img{
      width: 50%;
      height: auto;
      margin-top: 20px;
    }
    .btn{
      height: 30px;
      line-height: 30px;
      margin: 20px 2px 0 2px;
    }
  }
  .gold-list{
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    align-content: center;
    .list{
      float: left;
      width: 140px;
      height: 140px;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(33,66,171,0.1500);
      border-radius: 20px;
      padding: 20px;
      box-sizing: border-box;
      margin: 10px;
      &:last-child{
        margin-right: 0;
      }
      .name{
        font-size: 20px;
        font-weight: 500;
        color: #0D73D6;
        line-height: 25px;
      }
      .des{
        font-size: 14px;
        font-weight: 400;
        color: rgba($color: #000000, $alpha: 0.6);
        line-height: 22px;
        margin-top: 9px;
      }
      .num{
        font-size: 24px;
        font-weight: 500;
        color: #000000;
        line-height: 25px;
        margin-top: 9px;
      }
    }
  }
</style>