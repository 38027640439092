import {
  getGbpChart,
  getHistoryRatesInfo,
  getArticle,
  getArticleDetail,
  getPriceBySymbol,
  sendQuestion,
  leadsSave
} from '@/api/gbp'
import spreads from '@/config/spreads.json'

export class GbpService {
  async getChart(query) {
    const { data } = await getGbpChart(query)
    return data.map(d => {
      const { o, c, l, h, t } = d

      return {
        time: new Date(t),
        data: [o, c, l, h]
      }
    })
  }

  async getArticle(page, lang) {
    if (lang === 'zh' || lang === 'tw') lang = 'cn'
    const { data } = await getArticle(page, lang)
    return data.data.filter(item => item.post_title !== '')
  }

  async getArticleDetail(id, lang) {
    if (lang === 'zh' || lang === 'tw') lang = 'cn'
    const { data } = await getArticleDetail(id, lang)
    return data
  }

  async getHistoryRatesInfo(query) {
    const { data } = await getHistoryRatesInfo(query)
    return data.rateinfos
      ? data.rateinfos.map(d => {
          let { time, open, close, low, high, digits, vol } = d
          let digit = Math.pow(10, digits)
          let buy = (open + close + spreads[query.symbol]) / digit
          close = (open + close) / digit
          low = (open + low) / digit
          high = (open + high) / digit
          open = open / digit
          return {
            symbol: query.symbol,
            time: new Date(time * 1000),
            date: new Date(time * 1000).getDate(),
            data: [open, close, low, high, digits, vol, buy]
          }
        })
      : {
          symbol: query.symbol,
          time: new Date(),
          data: []
        }
  }

  async getHistoryRatesInfoK(query) {
    const { data } = await getHistoryRatesInfo(query)
    return data.rateinfos
      ? data.rateinfos.map(d => {
        let { time, open, close, low, high, digits, vol } = d
        let digit = Math.pow(10, digits)
        let buy = (open + close + spreads[query.symbol]) / digit
        close = (open + close) / digit
        low = (open + low) / digit
        high = (open + high) / digit
        open = open / digit
        return {
          symbol: query.symbol,
          time: new Date(time * 1000),
          date: new Date(time * 1000).getDate(),
          data: [open, close, low, high, digits]
        }
      })
      : {
        symbol: query.symbol,
        time: new Date(),
        data: []
      }
  }

  async getPriceBySymbol(query) {
    const { data } = await getPriceBySymbol(query)
    return data.data
  }

  async sendQuestion(query) {
    const { data } = await sendQuestion(query)
    return data
  }

  async sendLeads(query) {
    const { data } = await leadsSave(query)
    return data
  }

}
