<template>
    <div class="home-footer">
        <disclaimer/>
    </div>
</template>

<script>
  import Disclaimer from './disclaimer'
  export default {
    name: 'home-footer-phone',
    components: { Disclaimer }
  }
</script>

<style scoped lang="scss">
    .home-footer {
        height: 480px;
        @include bg-image('../../assets/image/home/home-footer-bg');
        background-size: cover;
        background-position: center center;
    }
</style>