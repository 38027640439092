import json from './ja.json'
export default {
  lang: {
    zh: '中文',
    en: 'English',
    ja: "Japanese",
    de: "German",
    fr: "French",
    vn: "Vietnamese",
    zh_tw: "繁体中文"
  },
  ...json
}
