<template>
  <div class="container footer-slider">
    <h3>{{$t('home.tradingPlatform.tradingPlatform')}}</h3>
    <el-carousel :interval="500000" arrow="never" height="380px">
      <el-carousel-item key="1">
        <div class="flex">
          <div class="left flex flex-column">
            <h4>{{$t('home.tradingPlatform.service')}} </h4>
            <p>{{$t('home.tradingPlatform.describe')}}</p>
            <span><img src="../../assets/image/home/icon-download.png" alt="" srcset="" />{{$t('home.tradingPlatform.download')}}</span>
            <div>
              <button class="btn btn-primary" @click="$open('pc')">{{$t('home.tradingPlatform.pc')}}</button>
              <button class="btn btn-primary" @click="$open('mac')">{{$t('home.tradingPlatform.MAC')}}</button>
              <button class="btn" @click="$open('android')" style="margin-right: 5px">{{$t('home.tradingPlatform.android')}}</button>
              <button class="btn" @click="$open('iOS')">{{$t('home.tradingPlatform.ios')}}</button>
            </div>
          </div>
          <div class="right">
          </div>
        </div>

      </el-carousel-item>
      <el-carousel-item key="2">
        <div class="flex">
          <div class="left flex flex-column">
            <h4>{{$t('home.tradingPlatform.service5')}}</h4>
            <p>{{$t('home.tradingPlatform.describe')}}</p>
            <span><img src="../../assets/image/home/icon-download.png" alt="" srcset="" />{{$t('home.tradingPlatform.download')}}</span>
            <div>
              <button class="btn btn-primary" @click="$open('login5')">{{$t('home.tradingPlatform.pc')}}</button>
              <button class="btn btn-primary" @click="$open('login5')">{{$t('home.tradingPlatform.MAC')}}</button>
              <button class="btn" @click="$open('login5')" style="margin-right: 5px">{{$t('home.tradingPlatform.android')}}</button>
              <button class="btn" @click="$open('login5')">{{$t('home.tradingPlatform.ios')}}</button>
            </div>
          </div>
          <div class="right right5" style="order: -1; margin-left: 0; margin-right: 64px">
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'footer-slider',
}
</script>

<style scoped lang="scss">
.footer-slider {
  padding: 80px 0;
  h3 {
    margin-bottom: 70px;
    color: #000;
    font-size: 35px;
    text-align: center;
  }
  .left {
    width: 430px;
    align-items: flex-start;
    h4 {
      color: #0D73D6;
      font-size: 38px;
      margin-top: 16px;
    }
    p {
      font-size: 16px;
      color: #0009;
      height: 44px;
      line-height: 22px;
      margin: 18px 0 60px;
    }
    span {
      font-size: 14px;
      color: #0009;
      margin-bottom: 22px;
      img {
        vertical-align: middle;
        margin-right: 8px;
      }
    }
    .btn {
      color: #0D73D6;
      min-width: 100px;
    }
    .btn-primary {
      margin-right: 5px;
      color: #fff;
    }
  }
  .right {
    width: 467px;
    height: 278px;
    margin-left: 64px;
    @include bg-image('../../assets/image/home/footer-slider');
  }

  .right5 {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @include bg-image('../../assets/image/platform/device-MT5');
  }

  /deep/ .el-carousel__indicator--horizontal {
    padding: 12px 7px;
    .el-carousel__button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(13, 115, 214, 1);
    }
  }

  //@include bg-image('../../assets/image/home/footer-slider-bg');
}
</style>
