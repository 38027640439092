<template>

  <div class="usd-box">
    <div class="left">
      <!-- <img src="@/assets/img/home/usd.png" />
      <div>
        <div class="tit">{{ $t('newAdd.tip2') }}</div>

        <p class="tip">
          {{ $t('home.gpbUsd.service') }}
        </p>
      </div> -->

      <!-- <div class="btn-group">
        <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">
          {{ $t('common.registerNow') }}
        </button>
        <button class="btn btn-primary" @click="$open('signUp5', $i18n.locale)">
          {{ $t('common.registerNow5') }}
        </button>
      </div> -->
    </div>
    <div class="right">
      <gpb-chart></gpb-chart>
    </div>
  </div>

</template>

<script>
import GpbChart from './gpb-chart'
export default {
  name: 'gpb-usd',

  components: {
    GpbChart,
  },

  data() {
    return {
    }
  },

  computed: {
  },

  methods: {
  },
}
</script>

<style scoped lang="scss">
.usd-box {
  width: 1350px;
  margin: 50px auto 50px auto;

  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 39px;

    .tit {
      font-family: MicrosoftYaHeiUI-Bold;
      font-size: 48px;
      font-weight: bold;
      line-height: 86px;
      color: #132148;
    }

    .tip {
      font-size: 18px;
      font-weight: bold;
      line-height: 28px;
      color: #ffffff;
      padding-top: 10px;
    }

    .chart-left {
      width: 193px;
      height: 112px;
      background-size: 100%;
      background-repeat: no-repeat;
      margin-top: 33px;
      @include bg-image('../../../assets/image/home/chart-left')
    }

    .chart-left-1 {
      width: 193px;
      height: 120px;
      line-height: 40px;
      font-size: 34px;
      font-weight: bolder;
      color: #0D73D6;
    }



    .btn-group {
      display: flex;

      .btn {
        white-space: nowrap;
        padding: 0 4px;
        margin-right: 4px;
      }
    }
  }

  .right {
    width: 1350px;
    margin-top: 59px;
    background-color: #000000;
    box-shadow: 0px 0px 21px 0px rgba(201, 152, 89, 0.52);
    border-radius: 10px;
    border: solid 1px #132148;
  }
}

@media screen and (max-width: 992px) {
  .usd-box {
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: column;
    margin: 50px auto 0 auto;

    .left {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      flex-direction: column;

      img {
        width: 50%;
        margin: 0 auto;
        display: block;
      }

      .tit {
        font-size: 20px;
        line-height: 36px;
      }

      .tip {
        font-size: 13px;
        line-height: 26px;
      }
    }

    .right {
      width: 100%;
    }
  }
}
</style>
