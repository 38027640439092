const color = {
  green: '#24C098',
  red: '#ED5445',
  text: '#fff',
  theme: '#F2C59E',
  axisLabelText: '#F2C59E',
  axisLabelBg: 'rgb(52, 43, 48)',
  toolTipBg: 'rgba(0, 0,0, .9)',
}

// 坐标轴的线条样式
const getAxisLine = () => ({
  lineStyle: {

  },
})

// 坐标上的刻度样式
const getAxisTick = () => ({
  lineStyle: {},
})

// 坐标grid中的分割线
const getSplitLine = () => ({
  show: true,
  lineStyle: {
    color: 'rgba(255, 255, 255, 0.16)',
  },
})

// 坐标刻度标签颜色
const getAxisLabel = () => ({
  color: color.text,
  margin: 16,
  fontFamily: 'SFUIText',
})

// 坐标轴上的名称
const NAME_TEXT_STYLE = {}

// 气泡
const getToolTip = () => ({
  appendToBody: true,
  borderColor: 'transparent',
  backgroundColor: color.toolTipBg,
  axisPointer: {
    label: {
      show: true,
    },
    lineStyle: {
      opacity: 0.62,
    },
  },
  textStyle: {
    color: color.text,
  },
})

// 坐标
const getGrid = () => ({
  left: 20,
  right: 20,
  top: 20,
  bottom: 20,
  containLabel: true,
})

const getLegend = () => ({
  bottom: '0%',
  itemGap: 40,
})

// 标题
const getTitle = () => ({
  textStyle: {
    fontSize: 12,
    bottom: '-20px',
  },
})

const getAxisPointer = () => ({
  label: {
    color: color.axisLabelText,
    backgroundColor: color.axisLabelBg,
  },
})

function getDefaultChartOption() {
  return {
    grid: getGrid(),

    tooltip: getToolTip(),
    title: getTitle(),
    legend: getLegend(),
    axisPointer: getAxisPointer(),

    xAxis: {
      axisLine: getAxisLine(),
      axisTick: getAxisTick(),
      splitLine: getSplitLine(),
      axisLabel: getAxisLabel(),
    },

    yAxis: {
      axisLine: getAxisLine(),
      axisTick: getAxisTick(),
      splitLine: getSplitLine(),
      axisLabel: getAxisLabel(),
    },
  }
}
export { color, getDefaultChartOption }
