<template>
  <div class="advantage">

    <h4>{{ $t('newAdd.tip3') }}</h4>

    <ul class="advantage-list flex flex__justify-space-between flex__align-item-center">
      <li class="advantage-item a">
        <span>{{ $t('home.advantage.service3') }}</span>
        <small>{{ $t('home.advantage.describe3') }}</small>
        <div class="btn-group">
          <button class="common-but btn" @click="$open('signUp', $i18n.locale)">{{ $t('common.registerNow')
            }}</button>
          <button class="common-but btn" @click="$open('signUp5', $i18n.locale)">{{ $t('common.registerNow5')
            }}</button>
        </div>
      </li>

      <li class="advantage-item b">

        <span>{{ $t('home.advantage.service2') }}</span>
        <small>{{ $t('home.advantage.describe2') }}</small>
        <div class="btn-group">
          <button class="common-but btn" @click="$open('signUp', $i18n.locale)">{{ $t('common.registerNow')
            }}</button>
          <button class="common-but btn" @click="$open('signUp5', $i18n.locale)">{{ $t('common.registerNow5')
            }}</button>
        </div>
      </li>

      <li class="advantage-item c">

        <span>{{ $t('newAdd.tip4') }}</span>
        <small>{{ $t('newAdd.tip5') }}</small>
        <div class="btn-group">
          <!--<button class="common-but btn" @click="$open('activity', $i18n.locale)">{{ $t('common.learnMore') }}</button>-->
        </div>
      </li>
      <li class="advantage-item d">

        <span>{{ $t('home.advantage.service1') }}</span>
        <small>{{ $t('home.advantage.describe1') }}</small>
        <div class="btn-group">
          <!--<button class="common-but btn" @click="$open('activity', $i18n.locale)">{{ $t('common.learnMore') }}</button>-->
        </div>

      </li>


    </ul>

  </div>
</template>

<script>
export default {
  name: 'advantage',

  data() {
    return {}
  },

  computed: {
    reasonList: () =>
      Array(4)
        .fill('')
        .map(() => ({
          title: 'Global Operation',
          content:
            'Global Operations is a first-person tactical shooter video game developed ased in March 2002, following …',
        })),
  },
}
</script>

<style scoped lang="scss">
.advantage {
  width: 1460px;
  margin: 0 auto;

  h4 {
    font-family: MicrosoftYaHeiUI-Bold;
    font-size: 48px;
    font-weight: bold;
    line-height: 48px;
    color: #ffffff;
    text-align: center;
    padding: 199px 0 100px 0;
  }
}

.advantage-list {

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 44px;

  .advantage-item {
    width: 707px;
    height: 390px;
    box-sizing: border-box;
    padding: 53px 0 0 59px;
    color: #fff;
    display: flex;
    flex-direction: column;

    &.a {
      background: url(../../assets/img/home/follow.png) no-repeat center center;
      background-size: 100% 100%;
    }

    &.b {
      background: url(../../assets/img/home/extreme.png) no-repeat center center;
      background-size: 100% 100%;
    }

    &.c {
      background: url(../../assets/img/home/service.png) no-repeat center center;
      background-size: 100% 100%;
    }

    &.d {
      background: url(../../assets/img/home/bonus.png) no-repeat center center;
      background-size: 100% 100%;
    }



    span {
      font-size: 36px;
      font-weight: bold;
      line-height: 36px;
    }

    small {
      font-size: 24px;
      font-weight: bold;
      line-height: 36px;
      color: #999999;
      padding-top: 60px;
      width: 60%;
    }

    .btn-group {
      display: flex;
      gap: 40px;
      margin-top: 57px;

      .btn {
        min-width: 172px;
        height: 54px;
        line-height: 46px;
        padding: 0 20px;
        border-radius: 20px;
        border: solid 4px #132148;
        font-family: MicrosoftYaHeiUI;
        font-size: 19px;
        font-weight: bold;
        color: #f6f6f6;
        text-align: center;
      }
    }


  }


}

.slider {
  background-size: cover;

  //@include bg-image('../../assets/image/home/adv-slider-bg');
  .title {
    text-align: center;
    margin-top: 96px;
    font-size: 40px;
    font-weight: 600;
    @include title-bold();
  }

  .reason-list {
    padding: 40px 0 96px 0;

    .reason-item {
      width: 282px;
      height: 270px;
      padding: 32px 24px;
      border: 1px solid var(--border);
      background-color: var(--theme-bg-opacity);

      i {
        font-size: 28px;
        color: var(--theme-primary);
      }

      .reason-item__title {
        margin-top: 24px;
        font-size: 26px;
        font-weight: bold;
        @include one-line-ellipsis();
      }

      .reason-item__content {
        display: flow-root;
        margin-top: 14px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        color: var(--text-lighter);
        @include line-ellipsis(4);
      }
    }
  }
}

@media screen and (max-width: 992px) {


  .advantage {
    width: 100%;

    h4 {
      padding: 30px 0;
      font-size: 20px;
      box-sizing: content-box;
    }
  }

  .advantage-list {
    gap: 20px;
  }

  .advantage-list .advantage-item {
    width: 92vw;
    padding: 20px 0 0 20px;
    height: 240px;

    span {
      font-size: 20px;
    }

    small {
      font-size: 16px;
      padding-top: 20px;
      line-height: 26px;
    }

    .btn-group {
      gap: 20px;
      margin-top: 20px;

      .btn {
        font-size: 11px;
        padding: 0 10px;
        min-width: 45%;
        border: solid 2px #132148
      }
    }

  }
}
</style>
