import varieties from '@/config/varieties.json'

const returnFloat = (value, apiCode) => {
  let fix = digits(apiCode)
  let digit = Math.pow(10, fix)
  value = Math.round(parseFloat(value) * digit) / digit
  let s = value.toString().split('.')
  if (s.length == 1) {
    value = value.toString() + '.'
    Array(fix)
      .fill('')
      .forEach(() => {
        value = value + '0'
      })
  } else if (s.length > 1) {
    if (s[1].length < fix) {
      for (let i = s[1].length; i < fix; i++) {
        value = value + '0'
      }
    }
  }
  return typeof value === 'string' ? value : value.toString()
}

const digits = apiCode => {
  if (apiCode.indexOf('JPY') > -1) {
    return 3
  }
  if (apiCode === 'XAUUSD') {
    return 2
  }
  let product = ''
  Object.keys(varieties).map(key => {
    let idx = varieties[key].findIndex(varietie => {
      return varietie.apiCode === apiCode
    })
    if (idx > -1) {
      product = key
    }
  })
  let digit =
    ['forex', 'crypto'].indexOf(product) > -1 ? 5 : ['shares', 'commodities', 'indices'].indexOf(product) > -1 ? 2 : 3
  return digit
}

export { returnFloat, digits }
