<template>
  <div>
    <div class="container instruments__wrapper">
      <h1 class="title">{{ hottestTitle }}</h1>
      <div class="flex__justify-center instrument-block__wrapper">
        <hottest-block :apiCode="apiCode" v-for="apiCode in apiCodes" :key="apiCode"></hottest-block>
      </div>
    </div>
  </div>
</template>

<script>
import HottestBlock from './hottest-block'

export default {
  name: 'HottestForex',
  components: {
    HottestBlock
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    apiCodes: {
      type: Array,
      default: () => ['XAUUSD', 'EURUSD', 'GBPUSD', 'AUDUSD']
    }
  },

  computed: {
    hottestTitle() {
      return this.title !== '' ? this.title : this.$t('common.hottestTitle')
    }
  }
}
</script>

<style scoped lang="scss">
h1.title {
  font-family: MicrosoftYaHeiUI-Bold;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  padding: 80px 0 74px 0;
  @include title-bold();
}

.instruments__wrapper {

  .instrument-block__wrapper {
    gap:20px;
    display: flex;
  }
}


/* 超小屏幕（手机，小于 768px） */
@media screen and (max-width: 992px) {
  .container {
    width: 100vw;
    padding-bottom: 30px !important;
  }

  h1 {
    width: 100%;
    height: 22.5px;
    font-size: 20px !important;
    font-family: Barlow-ExtraBold, Barlow;
    font-weight: 800;
    color: #ffffff;
    line-height: 22.5px;

    text-align: center;

    &.title {
      padding: 30px 0;
      box-sizing: content-box;
    }
  }

  .instruments__wrapper {
    margin-top: 0px;
    padding: 0 16px;

    .instrument-block__wrapper {
      width: 95%;
      margin: 20px auto;
      display: block;
    }
  }
}
</style>
