<template>

  <div class="view-faq">
    <SupportBan :title="title"></SupportBan>
    <div class="warpper">
      <h3>{{ $t("support.faq.title") }}</h3>

      <h4>{{ $t("support.faq.describe1") }}</h4>
      <img  src="@/assets/img/support/1s.png">
 
      <!-- <img src="@/assets/image/support/step1-en.png"> -->

      <h4>{{ $t("support.faq.describe2") }}</h4>
      <img  src="@/assets/img/support/2s.png">
      <img  src="@/assets/img/support/3s.png">

      <img  src="@/assets/img/support/4.png">
     

      <h4>{{ $t("support.faq.describe3") }}</h4>
      <img v-if="$i18n.locale == 'zh'" src="@/assets/image/support/step3-1.png">
      <h4>{{ $t("support.faq.describe4") }}</h4>
      <img   src="@/assets/img/support/5.png">
  
      <h4>{{ $t("support.faq.describe5") }}</h4>
      <img  src="@/assets/image/support/step5-en.png">
    
      <h4>{{ $t("support.faq.describe6") }}</h4>
      <h4>{{ $t("support.faq.describe61") }}</h4>
      <img  src="@/assets/image/support/step6-en.png">
      

      <div class="btn-box">
        <button class="common-but" @click="$open('signUp', $i18n.locale)">{{
          $t("common.registerNow") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import SupportBan from "@/components/support/SupportBan";
export default {
  components: {
    SupportBan
  },
  data() {
    return {
      title: "Q&A"
    }
  },
 
}
</script>
<style scoped lang="scss">
.view-faq {

  .warpper {
    width: 1200px;
    margin: 0 auto;

    h3 {
      font-size: 24px;
      font-weight: bold;
      font-weight: 500;
      color: #fff;
      line-height: 24px;
      margin-bottom: 20px;
      padding-top: 140px;
      padding-bottom: 40px;
      border-bottom: 1px solid #999;
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      line-height: 22px;
      margin: 20px 0;
    }

    img {
      width: 100%;
      margin-bottom: 20px;
    }

    .btn-box {
      margin: 40px 0 60px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 992px) {
  .view-faq {


    .warpper {
      width: 100vw;
      padding: 0 15px;

      h3 {
        width: 100%;
        height: 22.5px;
        line-height: 22.5px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin-top: 10px;
        padding-top: 30px;
      }

      h4 {
        font-size: 13px;
      }

      img {
        width: 100%;
      }

      .btn-box {
        height: 30px;
        line-height: 30px;
        margin: 10px 0 30px;
      }
    }
  }
}
</style>