<template>
  <div class="fund-info">
    <div class="warpper">

      <div class="info-box">
        <h3>{{ $t("support.fundInfo.title") }}</h3>
        <p>{{ $t("support.fundInfo.describe") }}</p>
        <!-- <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">{{ $t("support.registerNow")
              }}</button> -->
        <div class="gold-list">
          <div class="list" v-for="(item, index) in gold" :key="index">

            <div class="des">{{ item.des }}</div>
            <div class="num">{{ item.num }}{{ item.name }}</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  computed: {
    gold() {
      return [
        {
          name: 'USDT',
          des: this.$t('support.fundInfo.golden'),
          num: '10'
        },
        // {
        //   name: 'AUD',
        //   des: this.$t('support.fundInfo.golden'),
        //   num: '200'
        // }, {
        //   name: 'EUR',
        //   des: this.$t('support.fundInfo.golden'),
        //   num: '300'
        // }, {
        //   name: 'GBP',
        //   des: this.$t('support.fundInfo.golden'),
        //   num: '400'
        // }, {
        //   name: 'NZD',
        //   des: this.$t('support.fundInfo.golden'),
        //   num: '500'
        // }
      ]
    }
  }
}
</script>
<style scoped lang="scss">
.fund-info {
  padding-bottom: 100px;

  .warpper {
    width: 1118px;
    height: 515px;
    background: url(../../assets/img/support/withdrawal.png) no-repeat center center;
    background-size: 100% 100%;
    margin: 100px auto 0 auto;
  }



  .info-box {
    display: inline-block;
    padding-top: 100px;
    padding-left: 120px;
    color: #ffffff;

    h3 {
      width: 560px;
      font-family: MicrosoftYaHeiUI-Bold;
      font-size: 45px;
      font-weight: bold;
      line-height: 66px;
    }

    p {
      width: 560px;
      font-family: MicrosoftYaHeiUILight;
      font-size: 15px;
      font-weight: bold;
      line-height: 24px;
    }

    button {
      margin-top: 40px;
    }
  }

  .gold-list {
    display: flex;
    margin-top: 60px;

    .list {

      &:last-child {
        margin-right: 0;
      }

      .des {
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
      }

      .num {
        font-size: 45px;
        font-weight: bold;
        line-height: 66px;

      }
    }
  }
}


@media screen and (max-width: 868px) {
  .fund-info{
    padding-bottom:20px;
  }
  .fund-info .warpper {
    width: 95vw;
    height: 200px;
    margin-top:30px;

    .info-box {
      padding-top: 20px;
      padding-left: 20px;

      .gold-list {
        margin-top: 20px;

        .des {
          font-size: 12px;
          line-height: 18px;
        }

        .num {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }

    h3 {
      font-size: 13px;
      line-height: 20px;
      width: 300px;
    }

    p {
      width: 300px;
      font-size: 11px;
      line-height: 18px;
    }

  }
}
</style>