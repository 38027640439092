<template>
  <div class="box">
    <div class="pic">
      <img src="@/assets/image/platform/time-MT5.png" alt="时间图">
    </div>
    <div class="des">
      <h3>{{$t("platform.mt5.title3")}}</h3>
      <p class="info">{{$t("platform.mt5.describe3")}}</p>
      <button class="btn btn-primary">{{$t("platform.mt5.apply")}}</button>
    </div>
  </div>
</template>

<script>
export default {
  components:{},
  props:{},
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped lang="scss">
.box{
  display: flex;
  padding: 120px 0;
  .pic, .des{
    flex: 1;
  }
  .pic{
    text-align: right;
    padding-right: 36px;
    img{
      // border: 1px dashed #000;
      display: inline-block;
    }
  }
  .des{
    padding-left: 36px;
    h3{
      height: 50px;
      font-size: 36px;
      font-weight: bold;
      color: #000000;
      line-height: 50px;
      margin-top: 20px;
    }
    .info{
      margin-top: 20px;
      font-size: 16px;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.6);
      line-height: 28px;
    }
    button{
      margin-top: 30px;
    }
  }
}
</style>