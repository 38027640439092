<template>
  <div>
    <AboutBan :title="title"></AboutBan>
    <div data-elementor-type="wp-page" data-elementor-id="31" class="elementor elementor-31"
      data-elementor-post-type="page">
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-3c672559 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="3c672559" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-703e810b"
            data-id="703e810b" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <div
                class="elementor-element elementor-element-5447f906 animated-slow elementor-widget elementor-widget-heading animated fadeInLeft"
                data-id="5447f906" data-element_type="widget"
                data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="heading.default">
                <div class="elementor-widget-container">
                  <h5 class="elementor-heading-title elementor-size-default">{{ $t("newest.about_tip1") }}</h5>
                </div>
              </div>
              <div class="elementor-element elementor-element-7e6ae68f elementor-widget elementor-widget-heading"
                data-id="7e6ae68f" data-element_type="widget" data-widget_type="heading.default">
                <div class="elementor-widget-container">
                  <h2 class="elementor-heading-title elementor-size-default">{{ $t("newest.about_tip2") }}
                  </h2>
                </div>
              </div>
              <div class="elementor-element elementor-element-715d4b26 elementor-widget elementor-widget-text-editor"
                data-id="715d4b26" data-element_type="widget" data-widget_type="text-editor.default">
                <div class="elementor-widget-container">
                  <p>{{ $t("newest.about_tip3") }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-733edc5"
            data-id="733edc5" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-7366114 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="7366114" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6fc8431"
                    data-id="6fc8431" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-30daeaa elementor-view-default elementor-position-top elementor-widget elementor-widget-icon-box"
                        data-id="30daeaa" data-element_type="widget" data-widget_type="icon-box.default">
                        <div class="elementor-widget-container">
                          <link rel="stylesheet"
                            href="..\wp-content\plugins\elementor\assets\css\widget-icon-box.min.css">
                          <div class="elementor-icon-box-wrapper">

                            <div class="elementor-icon-box-icon">
                              <span class="elementor-icon elementor-animation-grow">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                  id="Layer_1" x="0px" y="0px" viewBox="0 0 300 300"
                                  style="enable-background:new 0 0 300 300;" xml:space="preserve">
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <path
                                            d="M273.2,259.4H98.8V156.8h174.4V259.4z M108.7,249.5h154.6v-82.8H108.7V249.5z">
                                          </path>
                                        </g>
                                        <g>
                                          <path
                                            d="M244.8,241.7H127.3l-1.3-2.9c-1.3-2.8-3.6-5.1-6.4-6.3l-3-1.3v-46.5l3-1.3c2.8-1.2,5.1-3.4,6.3-6.1l1.3-2.8h117.6     l1.3,2.8c1.3,2.8,3.5,4.9,6.3,6.1l3,1.3v46.5l-3,1.3c-2.9,1.2-5.1,3.4-6.4,6.3L244.8,241.7z M133.4,231.8h105.3     c1.8-2.8,4.2-5.1,7-6.8v-34c-2.7-1.7-5.1-3.9-6.8-6.6H133.2c-1.8,2.7-4.1,4.9-6.8,6.6v34C129.2,226.7,131.6,229,133.4,231.8z">
                                          </path>
                                        </g>
                                        <g>
                                          <path
                                            d="M186,226c-9.9,0-18-8.1-18-18s8.1-18,18-18s18,8.1,18,18C204,218,195.9,226,186,226z M186,200c-4.5,0-8.1,3.6-8.1,8.1     s3.6,8.1,8.1,8.1s8.1-3.6,8.1-8.1S190.5,200,186,200z">
                                          </path>
                                        </g>
                                        <g>
                                          <g>
                                            <g>
                                              <rect x="216.1" y="203.1" width="19.4" height="9.9"></rect>
                                            </g>
                                          </g>
                                          <g>
                                            <g>
                                              <rect x="136.5" y="203.1" width="19.4" height="9.9"></rect>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                    <g>
                                      <g>
                                        <g>
                                          <polygon
                                            points="65.6,184.4 50.4,169.2 35.2,184.4 28.3,177.4 50.4,155.3 72.6,177.4     ">
                                          </polygon>
                                        </g>
                                      </g>
                                      <g>
                                        <path
                                          d="M85.6,215.1H68.8c-13.1,0-23.8-10.7-23.8-23.8v-23.2h9.9v23.2c0,7.7,6.2,13.9,13.9,13.9h16.8    C85.6,205.2,85.6,215.1,85.6,215.1z">
                                        </path>
                                      </g>
                                    </g>
                                    <g>
                                      <g>
                                        <path
                                          d="M201.2,143.2H26.8V40.6h174.4V143.2z M36.7,133.3h154.6V50.5H36.7V133.3z">
                                        </path>
                                      </g>
                                      <g>
                                        <path d="M183.5,125.5H77.8V58.3h105.7V125.5z M87.7,115.7h85.9V68.2H87.7V115.7z">
                                        </path>
                                      </g>
                                      <g>
                                        <g>
                                          <rect x="47" y="60.8" width="10.6" height="9.9"></rect>
                                        </g>
                                      </g>
                                      <g>
                                        <g>
                                          <rect x="47" y="115.7" width="21.9" height="9.9"></rect>
                                        </g>
                                      </g>
                                      <g>
                                        <g>
                                          <rect x="97" y="73" width="19.4" height="9.9"></rect>
                                        </g>
                                      </g>
                                      <g>
                                        <g>
                                          <rect x="156.8" y="47.6" width="9.9" height="91.4"></rect>
                                        </g>
                                      </g>
                                    </g>
                                    <g>
                                      <g>
                                        <g>
                                          <polygon
                                            points="249.6,144.7 227.4,122.6 234.4,115.6 249.6,130.8 264.8,115.6 271.7,122.6     ">
                                          </polygon>
                                        </g>
                                      </g>
                                      <g>
                                        <path
                                          d="M255,131.9h-9.9v-23.2c0-7.7-6.2-13.9-13.9-13.9h-16.8v-9.9h16.8c13.1,0,23.8,10.7,23.8,23.8L255,131.9L255,131.9z">
                                        </path>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                            </div>

                            <div class="elementor-icon-box-content">

                              <h3 class="elementor-icon-box-title">
                                <span>{{ $t("newest.about_tip4") }}
                                </span>
                              </h3>


                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-86e4751"
                    data-id="86e4751" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-1b20d17 elementor-view-default elementor-position-top elementor-widget elementor-widget-icon-box"
                        data-id="1b20d17" data-element_type="widget" data-widget_type="icon-box.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-icon-box-wrapper">

                            <div class="elementor-icon-box-icon">
                              <span class="elementor-icon elementor-animation-grow">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                  id="Layer_1" x="0px" y="0px" viewBox="0 0 300 300"
                                  style="enable-background:new 0 0 300 300;" xml:space="preserve">
                                  <g>
                                    <g>
                                      <polygon
                                        points="263.8,266.8 30.2,266.8 30.2,33.2 40.1,33.2 40.1,256.9 263.8,256.9   ">
                                      </polygon>
                                    </g>
                                    <g>
                                      <g>
                                        <rect x="54.3" y="244.9" width="9.9" height="13.8"></rect>
                                      </g>
                                      <g>
                                        <rect x="85.9" y="244.9" width="9.9" height="13.8"></rect>
                                      </g>
                                      <g>
                                        <rect x="117.5" y="244.9" width="9.9" height="13.8"></rect>
                                      </g>
                                      <g>
                                        <rect x="149.1" y="244.9" width="9.9" height="13.8"></rect>
                                      </g>
                                      <g>
                                        <rect x="180.7" y="244.9" width="9.9" height="13.8"></rect>
                                      </g>
                                      <g>
                                        <rect x="212.3" y="244.9" width="9.9" height="13.8"></rect>
                                      </g>
                                      <g>
                                        <rect x="243.9" y="244.9" width="9.9" height="13.8"></rect>
                                      </g>
                                    </g>
                                    <g>
                                      <g>
                                        <rect x="34.7" y="40.7" width="13.8" height="9.9"></rect>
                                      </g>
                                      <g>
                                        <rect x="34.7" y="72.3" width="13.8" height="9.9"></rect>
                                      </g>
                                      <g>
                                        <rect x="34.7" y="103.9" width="13.8" height="9.9"></rect>
                                      </g>
                                      <g>
                                        <rect x="34.7" y="135.5" width="13.8" height="9.9"></rect>
                                      </g>
                                      <g>
                                        <rect x="34.7" y="167.1" width="13.8" height="9.9"></rect>
                                      </g>
                                      <g>
                                        <rect x="34.7" y="198.7" width="13.8" height="9.9"></rect>
                                      </g>
                                      <g>
                                        <rect x="34.7" y="230.3" width="13.8" height="9.9"></rect>
                                      </g>
                                    </g>
                                    <g>
                                      <polygon
                                        points="174.1,180.2 144.7,150.7 135.8,159.6 128.8,152.6 144.7,136.8 174.1,166.2 261.4,79 268.3,86   ">
                                      </polygon>
                                    </g>
                                    <g>
                                      <rect x="46" y="198.5"
                                        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -118.9789 119.692)" width="77.9"
                                        height="9.9"></rect>
                                    </g>
                                    <g>
                                      <polygon
                                        points="269.8,120.2 259.9,120.2 259.9,87.4 227.1,87.4 227.1,77.5 269.8,77.5   ">
                                      </polygon>
                                    </g>
                                    <g>
                                      <polygon
                                        points="269.8,176.7 259.9,176.7 259.9,144 227.1,144 227.1,134.1 269.8,134.1   ">
                                      </polygon>
                                    </g>
                                    <g>
                                      <polygon
                                        points="180,230.9 95.7,146.6 65.7,176.7 58.7,169.7 95.7,132.6 180,216.9 261.4,135.5 268.3,142.5   ">
                                      </polygon>
                                    </g>
                                  </g>
                                </svg> </span>
                            </div>

                            <div class="elementor-icon-box-content">

                              <h3 class="elementor-icon-box-title">
                                <span>
                                  {{ $t("newest.about_tip5") }}
                                </span>
                              </h3>


                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-993c414 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="993c414" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6465abb"
                    data-id="6465abb" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-ffd0c80 elementor-view-default elementor-position-top elementor-widget elementor-widget-icon-box"
                        data-id="ffd0c80" data-element_type="widget" data-widget_type="icon-box.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-icon-box-wrapper">

                            <div class="elementor-icon-box-icon">
                              <span class="elementor-icon elementor-animation-grow">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                  id="Layer_1" x="0px" y="0px" viewBox="0 0 300 300"
                                  style="enable-background:new 0 0 300 300;" xml:space="preserve">
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          d="M113,259.5c-48.5,0-87.9-39.4-87.9-87.9S64.5,83.7,113,83.7v9.9c-43,0-78,35-78,78s35,78,78,78c34.4,0,64.3-22,74.5-54.8    l9.4,2.9C185.4,234.7,151.7,259.5,113,259.5z">
                                        </path>
                                      </g>
                                      <g>
                                        <g>
                                          <path
                                            d="M129.6,95.2c-5.4-1.2-11-1.8-16.7-1.8v-9.9c6.3,0,12.6,0.7,18.8,2L129.6,95.2z">
                                          </path>
                                        </g>
                                        <g>
                                          <path
                                            d="M113,259.6c-29.7,0-53-38.6-53-88s23.3-88,53-88v9.9c-23.4,0-43.1,35.8-43.1,78.1s19.7,78.1,43.1,78.1     c20.9,0,39.2-28.3,42.5-65.8l9.8,0.9C161.5,228.1,139.5,259.6,113,259.6z">
                                          </path>
                                        </g>
                                        <g>
                                          <rect x="108" y="88.5" width="9.9" height="166.1"></rect>
                                        </g>
                                        <g>
                                          <path
                                            d="M190.6,205.8H31.9l-1.2-3.2c-3.8-10.1-5.7-20.5-5.7-31s1.9-21,5.7-31l1.2-3.2H134v9.9H38.8c-2.6,8-4,16.1-4,24.3     s1.3,16.4,3.9,24.3h151.8v9.9H190.6z">
                                          </path>
                                        </g>
                                      </g>
                                    </g>
                                    <g>
                                      <path
                                        d="M212.2,192.7h-29.1l-1.6-19.4c-4.5-1.4-8.7-3.2-12.7-5.6l-14.3,12.1l-20.6-20.6l12.6-14.9c-2.2-4.1-3.9-8.5-5-13   l-18.7-1.6v-29.1l19.4-1.6c1.4-4.5,3.2-8.7,5.6-12.7L135.7,72l20.6-20.6L171,64.1c4.1-2.2,8.5-3.9,13-5l1.6-18.7h29.1l1.6,19.4   c4.5,1.4,8.7,3.2,12.7,5.6l14.3-12.1l20.6,20.6l-12.6,14.9c2.2,4.1,3.9,8.5,5,13l18.7,1.6v29.1l-19.4,1.6   c-1.4,4.5-3.2,8.7-5.6,12.7l12.1,14.3l-20.6,20.6L226.7,169c-4.1,2.2-8.4,3.9-13,5L212.2,192.7z M192.2,182.8h10.9l1.4-17l3.7-0.7   c5.9-1.1,11.4-3.3,16.5-6.4l3-1.9L241,168l7.7-7.7l-11-13l2.1-3.1c3.3-4.9,5.8-10.4,7.2-16.2l0.8-3.5l17.3-1.4v-10.9l-17-1.4   l-0.7-3.7c-1.1-5.9-3.3-11.4-6.4-16.5l-1.9-3l11.2-13.3l-7.7-7.7l-13,11l-3.1-2.1c-4.9-3.3-10.4-5.8-16.2-7.2l-3.5-0.8l-1.4-17.3   h-10.9l-1.4,17l-3.7,0.7c-5.9,1.1-11.4,3.3-16.5,6.4l-3,1.9L156.7,65l-7.7,7.7l11,13l-2.1,3.1c-3.3,4.9-5.8,10.4-7.1,16.2   l-0.8,3.5l-17.3,1.4v10.9l17,1.4l0.7,3.7c1.1,5.9,3.3,11.4,6.4,16.5l1.9,3l-11.2,13.3l7.7,7.7l13-11l3.1,2.1   c4.9,3.3,10.4,5.8,16.2,7.2l3.5,0.8L192.2,182.8z">
                                      </path>
                                    </g>
                                    <g>
                                      <path
                                        d="M198.9,151c-19,0-34.4-15.5-34.4-34.4s15.5-34.4,34.4-34.4c19,0,34.4,15.5,34.4,34.4S217.9,151,198.9,151z M198.9,92   c-13.5,0-24.6,11-24.6,24.6c0,13.5,11,24.6,24.6,24.6c13.5,0,24.6-11,24.6-24.6C223.4,103,212.4,92,198.9,92z">
                                      </path>
                                    </g>
                                  </g>
                                </svg> </span>
                            </div>

                            <div class="elementor-icon-box-content">

                              <h3 class="elementor-icon-box-title">
                                <span>
                                  {{ $t("newest.about_tip6") }}
                                </span>
                              </h3>


                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ee4c20e"
                    data-id="ee4c20e" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-df3b7e7 elementor-view-default elementor-position-top elementor-widget elementor-widget-icon-box"
                        data-id="df3b7e7" data-element_type="widget" data-widget_type="icon-box.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-icon-box-wrapper">

                            <div class="elementor-icon-box-icon">
                              <span class="elementor-icon elementor-animation-grow">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                  id="Layer_1" x="0px" y="0px" viewBox="0 0 300 300"
                                  style="enable-background:new 0 0 300 300;" xml:space="preserve">
                                  <g>
                                    <g>
                                      <path
                                        d="M150,271.5l-7.9-2.3c-42.9-12.5-100-37.9-100-113V57.5l32.6,5.1c7.5,1.2,16,0.9,24.4-0.6c12.9-2.4,24.6-7.7,31.1-14.1   L150,28.5l19.8,19.4c6.5,6.4,18.2,11.7,31.1,14.1c8.4,1.6,17,1.8,24.4,0.6l32.6-5.1v98.6c0,75.1-57.1,100.5-100,113L150,271.5z    M52,69.1v87.1c0,68.5,53,91.9,92.9,103.5l5.2,1.5l5.2-1.5C195,248,248,224.6,248,156.1v-87l-21.2,3.3c-8.6,1.3-18.2,1.1-27.8-0.7   c-14.8-2.7-28.3-9-36.2-16.7L150,42.4L137.1,55c-7.9,7.7-21.4,14-36.2,16.7c-9.5,1.8-19.2,2-27.8,0.7L52,69.1z">
                                      </path>
                                    </g>
                                    <g>
                                      <path
                                        d="M150,250.9l-2.4-0.7c-76-22.2-85.8-61.9-85.8-94V80.6l9.8,1.5c9.6,1.5,20.4,1.3,31.1-0.7c16.9-3.1,32-10.2,41.3-19.4   l5.9-5.8l5.9,5.8c9.4,9.2,24.4,16.3,41.3,19.4c10.7,2,21.5,2.2,31.1,0.7l9.8-1.5v75.5c0,61.2-45.8,82.4-85.8,94L150,250.9z    M71.7,92.1v64c0,28.6,8.9,64.1,78.3,84.4c36.5-10.7,78.3-30,78.3-84.4v-64c-10.3,1.4-21.6,1.1-32.8-1   c-18.3-3.4-34.7-11-45.5-21.1c-10.8,10.1-27.2,17.7-45.5,21.1C93.4,93.2,82.1,93.6,71.7,92.1z">
                                      </path>
                                    </g>
                                    <g>
                                      <path
                                        d="M150,207.3c-28.2,0-51.1-22.9-51.1-51.1s22.9-51.1,51.1-51.1s51.1,22.9,51.1,51.1C201.1,184.3,178.2,207.3,150,207.3z    M150,114.9c-22.7,0-41.2,18.5-41.2,41.2s18.5,41.2,41.2,41.2s41.2-18.5,41.2-41.2S172.7,114.9,150,114.9z">
                                      </path>
                                    </g>
                                    <g>
                                      <g>
                                        <path
                                          d="M153.7,162.9l-13-5.1c-5.6-3.6-6.8-7.6-6.8-10.4V142c0-7.1,5.8-12.9,12.9-12.9h6.4c7.1,0,12.9,5.8,12.9,12.9v2.5h-9.9    V142c0-1.7-1.4-3-3-3h-6.4c-1.7,0-3,1.4-3,3v5.4l0,0c0,0,0.2,0.7,1.8,1.8l11.7,4.5L153.7,162.9z">
                                        </path>
                                      </g>
                                      <g>
                                        <path
                                          d="M153.2,183.4h-6.4c-7.1,0-12.9-5.8-12.9-12.9V168h9.9v2.5c0,1.7,1.4,3,3,3h6.4c1.7,0,3-1.4,3-3V165c0-0.2-0.5-0.9-2-1.9    l-11.1-4.3l3.6-9.2l12.5,4.9c5.7,3.6,6.9,7.7,6.9,10.5v5.4C166.1,177.6,160.3,183.4,153.2,183.4z">
                                        </path>
                                      </g>
                                      <g>
                                        <rect x="145.5" y="123" width="9.9" height="11.9"></rect>
                                      </g>
                                      <g>
                                        <rect x="145.5" y="176.5" width="9.9" height="11.9"></rect>
                                      </g>
                                    </g>
                                    <g>
                                      <g>
                                        <rect x="145.5" y="63.6" width="9.9" height="46.9"></rect>
                                      </g>
                                    </g>
                                    <g>
                                      <g>
                                        <rect x="145.5" y="202" width="9.9" height="42"></rect>
                                      </g>
                                    </g>
                                  </g>
                                </svg> </span>
                            </div>

                            <div class="elementor-icon-box-content">

                              <h3 class="elementor-icon-box-title">
                                <span>
                                  {{ $t("newest.about_tip7") }}
                                </span>
                              </h3>


                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <section
        class="elementor-section elementor-top-section elementor-element elementor-element-4216ff10 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
        data-id="4216ff10" data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
        <div class="elementor-background-overlay"></div>
        <div class="elementor-container elementor-column-gap-default">
          <div
            class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-26596a3f"
            data-id="26596a3f" data-element_type="column">
            <div class="elementor-widget-wrap elementor-element-populated">
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-47d992b9 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="47d992b9" data-element_type="section">
                <div class="elementor-container elementor-column-gap-no">
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-417bcbfb"
                    data-id="417bcbfb" data-element_type="column"
                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-232e4937 elementor-widget elementor-widget-heading"
                        data-id="232e4937" data-element_type="widget" data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">{{ $t("newest.about_tip8") }} </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-184c8bb3 elementor-widget elementor-widget-text-editor"
                        data-id="184c8bb3" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container" style="color:#000">
                          <p>{{ $t("newest.about_tip9") }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-27a608d"
                    data-id="27a608d" data-element_type="column"
                    data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div class="elementor-element elementor-element-8c43392 elementor-widget elementor-widget-heading"
                        data-id="8c43392" data-element_type="widget" data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">{{ $t("newest.about_tip10") }}</h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-6e03b98 elementor-widget elementor-widget-text-editor"
                        data-id="6e03b98" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container">
                          <p>{{ $t("newest.about_tip11") }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div
                class="elementor-element elementor-element-c26cccc animated-slow elementor-widget elementor-widget-heading"
                data-id="c26cccc" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;none&quot;}"
                data-widget_type="heading.default">
                <div class="elementor-widget-container">
                  <h2 class="elementor-heading-title elementor-size-default">{{ $t("newest.home_tip17")
                    }}&ZeroWidthSpace;</h2>
                </div>
              </div>
              <section
                class="elementor-section elementor-inner-section elementor-element elementor-element-c1e7ca1 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="c1e7ca1" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                  <div
                    class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-f2e3f47"
                    data-id="f2e3f47" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-ca6697f elementor-widget__width-auto elementor-widget elementor-widget-counter"
                        data-id="ca6697f" data-element_type="widget" data-widget_type="counter.default">
                        <div class="elementor-widget-container">

                          <div class="elementor-counter">
                            <div class="elementor-counter-number-wrapper">
                              <span class="elementor-counter-number-prefix"></span>
                              <span class="elementor-counter-number" data-duration="2000" data-to-value="2019"
                                data-from-value="0">2019</span>
                              <span class="elementor-counter-number-suffix"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="elementor-element elementor-element-0abda26 elementor-widget elementor-widget-heading"
                        data-id="0abda26" data-element_type="widget" data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">{{ $t("newest.home_tip44") }}</h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-f78e326 elementor-star-rating--align-center elementor--star-style-star_fontawesome elementor-widget elementor-widget-star-rating"
                        data-id="f78e326" data-element_type="widget" data-widget_type="star-rating.default">
                        <div class="elementor-widget-container">

                          <div class="elementor-star-rating__wrapper">
                            <div class="elementor-star-rating" title="5/5" itemtype="http://schema.org/Rating"
                              itemscope="" itemprop="reviewRating"><i class="elementor-star-full"></i><i
                                class="elementor-star-full"></i><i class="elementor-star-full"></i><i
                                class="elementor-star-full"></i><i class="elementor-star-full"></i> <span
                                itemprop="ratingValue" class="elementor-screen-only">5/5</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-539db95"
                    data-id="539db95" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-4b4f95d elementor-widget__width-auto elementor-widget elementor-widget-counter"
                        data-id="4b4f95d" data-element_type="widget" data-widget_type="counter.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-counter">
                            <div class="elementor-counter-number-wrapper">
                              <span class="elementor-counter-number-prefix"></span>
                              <span class="elementor-counter-number" data-duration="2000" data-to-value="2020"
                                data-from-value="0">2020</span>
                              <span class="elementor-counter-number-suffix"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="elementor-element elementor-element-bc34b74 elementor-widget elementor-widget-heading"
                        data-id="bc34b74" data-element_type="widget" data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">{{ $t("newest.home_tip45") }}</h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-1b5955d elementor-star-rating--align-center elementor--star-style-star_fontawesome elementor-widget elementor-widget-star-rating"
                        data-id="1b5955d" data-element_type="widget" data-widget_type="star-rating.default">
                        <div class="elementor-widget-container">

                          <div class="elementor-star-rating__wrapper">
                            <div class="elementor-star-rating" title="5/5" itemtype="http://schema.org/Rating"
                              itemscope="" itemprop="reviewRating"><i class="elementor-star-full"></i><i
                                class="elementor-star-full"></i><i class="elementor-star-full"></i><i
                                class="elementor-star-full"></i><i class="elementor-star-full"></i> <span
                                itemprop="ratingValue" class="elementor-screen-only">5/5</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ece9089"
                    data-id="ece9089" data-element_type="column">
                    <div class="elementor-widget-wrap elementor-element-populated">
                      <div
                        class="elementor-element elementor-element-11f219c elementor-widget__width-auto elementor-widget elementor-widget-counter"
                        data-id="11f219c" data-element_type="widget" data-widget_type="counter.default">
                        <div class="elementor-widget-container">
                          <div class="elementor-counter">
                            <div class="elementor-counter-number-wrapper">
                              <span class="elementor-counter-number-prefix"></span>
                              <span class="elementor-counter-number" data-duration="2000" data-to-value="2021"
                                data-from-value="0">2021</span>
                              <span class="elementor-counter-number-suffix"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="elementor-element elementor-element-ab0b1ea elementor-widget elementor-widget-heading"
                        data-id="ab0b1ea" data-element_type="widget" data-widget_type="heading.default">
                        <div class="elementor-widget-container">
                          <h2 class="elementor-heading-title elementor-size-default">{{ $t("newest.home_tip46") }}
                          </h2>
                        </div>
                      </div>
                      <div
                        class="elementor-element elementor-element-9d75ba4 elementor-star-rating--align-center elementor--star-style-star_fontawesome elementor-widget elementor-widget-star-rating"
                        data-id="9d75ba4" data-element_type="widget" data-widget_type="star-rating.default">
                        <div class="elementor-widget-container">

                          <div class="elementor-star-rating__wrapper">
                            <div class="elementor-star-rating" title="5/5" itemtype="http://schema.org/Rating"
                              itemscope="" itemprop="reviewRating"><i class="elementor-star-full"></i><i
                                class="elementor-star-full"></i><i class="elementor-star-full"></i><i
                                class="elementor-star-full"></i><i class="elementor-star-full"></i> <span
                                itemprop="ratingValue" class="elementor-screen-only">5/5</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>




    <!-- <div class="info-group">
      <div class="container">
        <div class="info-group__left">
          <div>
            <div class="info-group__speed"></div>
            <span>{{ $t("aboutUs.whoAreWe.tab1") }}</span>
          </div>
        </div>
        <div class="info-group__right">
          <div class="info-group__zeros">
            <div class="info-group__zero" :class="{ 'info-group__zero-locale': $i18n.locale == 'en' }">
              <span>{{ $t("aboutUs.whoAreWe.tab2") }}</span>
            </div>
            <div class="info-group__zero" :class="{ 'info-group__zero-locale': $i18n.locale == 'en' }">
              <span>{{ $t("aboutUs.whoAreWe.tab3") }}</span>
            </div>
          </div>
          <div class="info-group__asic"><a href="/dist/Certificate.jpg" target="_blank">
              <div class="info-group__asic-pic"></div>
              <span>{{ $t("aboutUs.whoAreWe.tab4") }}</span>
            </a>
          </div>
        </div>
      </div>
    </div> -->


    <!-- <div class="container map-container">
      <h4>{{ $t("aboutUs.whoAreWe.title2") }}</h4>
      <div class="map">
        <i :class="{ 'map__zero-locale': screenWidth < 992 }"></i>
      </div>
    </div> -->
  </div>
</template>

<script>
import AboutBan from './AboutBan.vue';
export default {
  name: 'who-are-we',
  computed: {
    screenWidth() {
      let width = localStorage.getItem('screen_width') || 1200
      return width
    },
  },
  components: {
    AboutBan,
  },
  computed: {
    title() {
      return this.$t('newest.aboutUs');
    }
  },
  // data() {
  //   return {
  //     title: this.$t("newest.aboutUs")
  //   }
  // }
}
</script>
<style lang="scss" src="./about.scss" scoped></style>
<style>
/*! elementor - v3.23.0 - 05-08-2024 */
.elementor-counter {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column-reverse
}

.elementor-counter .elementor-counter-number {
  flex-grow: var(--counter-number-grow, 0)
}

.elementor-counter .elementor-counter-number-wrapper {
  flex: 1;
  display: flex;
  font-size: 69px;
  font-weight: 600;
  line-height: 1;
  text-align: center
}

.elementor-counter .elementor-counter-number-prefix {
  text-align: end;
  flex-grow: var(--counter-prefix-grow, 1);
  white-space: pre-wrap
}

.elementor-counter .elementor-counter-number-suffix {
  text-align: start;
  flex-grow: var(--counter-suffix-grow, 1);
  white-space: pre-wrap
}

.elementor-counter .elementor-counter-title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 19px;
  font-weight: 400;
  line-height: 2.5
}
</style>
<style>
/*! elementor - v3.23.0 - 05-08-2024 */
@charset "UTF-8";

.elementor-star-rating {
  color: #ccd6df;
  font-family: eicons;
  display: inline-block
}

.elementor-star-rating i {
  display: inline-block;
  position: relative;
  font-style: normal;
  cursor: default
}

.elementor-star-rating i:before {
  content: "\e934";
  display: block;
  font-size: inherit;
  font-family: inherit;
  position: absolute;
  overflow: hidden;
  color: #f0ad4e;
  top: 0;
  left: 0
}

.elementor-star-rating .elementor-star-empty:before {
  content: none
}

.elementor-star-rating .elementor-star-1:before {
  width: 10%
}

.elementor-star-rating .elementor-star-2:before {
  width: 20%
}

.elementor-star-rating .elementor-star-3:before {
  width: 30%
}

.elementor-star-rating .elementor-star-4:before {
  width: 40%
}

.elementor-star-rating .elementor-star-5:before {
  width: 50%
}

.elementor-star-rating .elementor-star-6:before {
  width: 60%
}

.elementor-star-rating .elementor-star-7:before {
  width: 70%
}

.elementor-star-rating .elementor-star-8:before {
  width: 80%
}

.elementor-star-rating .elementor-star-9:before {
  width: 90%
}

.elementor-star-rating__wrapper {
  display: flex;
  align-items: center
}

.elementor-star-rating__title {
  margin-inline-end: 10px
}

.elementor-star-rating--align-right .elementor-star-rating__wrapper {
  text-align: right;
  justify-content: flex-end
}

.elementor-star-rating--align-left .elementor-star-rating__wrapper {
  text-align: left;
  justify-content: flex-start
}

.elementor-star-rating--align-center .elementor-star-rating__wrapper {
  text-align: center;
  justify-content: center
}

.elementor-star-rating--align-justify .elementor-star-rating__title {
  margin-inline-end: auto
}

@media (max-width:1024px) {
  .elementor-star-rating-tablet--align-right .elementor-star-rating__wrapper {
    text-align: right;
    justify-content: flex-end
  }

  .elementor-star-rating-tablet--align-left .elementor-star-rating__wrapper {
    text-align: left;
    justify-content: flex-start
  }

  .elementor-star-rating-tablet--align-center .elementor-star-rating__wrapper {
    text-align: center;
    justify-content: center
  }

  .elementor-star-rating-tablet--align-justify .elementor-star-rating__title {
    margin-inline-end: auto
  }
}

@media (max-width:767px) {
  .elementor-star-rating-mobile--align-right .elementor-star-rating__wrapper {
    text-align: right;
    justify-content: flex-end
  }

  .elementor-star-rating-mobile--align-left .elementor-star-rating__wrapper {
    text-align: left;
    justify-content: flex-start
  }

  .elementor-star-rating-mobile--align-center .elementor-star-rating__wrapper {
    text-align: center;
    justify-content: center
  }

  .elementor-star-rating-mobile--align-justify .elementor-star-rating__title {
    margin-inline-end: auto
  }
}

.last-star {
  letter-spacing: 0
}

.elementor--star-style-star_unicode .elementor-star-rating {
  font-family: Arial, Helvetica, sans-serif
}

.elementor--star-style-star_unicode .elementor-star-rating i:not(.elementor-star-empty):before {
  content: "★"
}
</style>
<style scoped lang="scss">
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
  background-color: #69727d;
  color: #fff
}

.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {
  color: #69727d;
  border: 3px solid;
  background-color: transparent
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {
  margin-top: 8px
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {
  width: 1em;
  height: 1em
}

.elementor-widget-text-editor .elementor-drop-cap {
  float: left;
  text-align: center;
  line-height: 1;
  font-size: 50px
}

.elementor-widget-text-editor .elementor-drop-cap-letter {
  display: inline-block
}

.banner {
  padding-bottom: 100px;

  [class^='banner'] {
    text-align: center;
  }

  &__title {
    color: #000;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    height: 50px;
    line-height: 50px;

    &::after {
      content: '';
      display: inline-block;
      width: 40px;
      height: 6px;
      background: #0D73D6;
      position: absolute;
      left: 50%;
      bottom: -2px;
      transform: translate3d(-50%, 0, 0);
    }
  }

  &__sub-title {
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #00000086;
    line-height: 22px;
    margin-top: 22px;
  }

  &__pics {
    margin-top: 72px;
    height: 230px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 60%;
    @include bg-image('../../assets/image/about-us/who-banner-bg');
  }
}

.info-group {
  height: 720px;
  background: #F5F6F8;
  padding: 80px 0;

  .container {
    display: flex;
  }

  span {
    display: inline-block;
    width: 100%;
    text-align: center;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 22px;

  }

  &__left {
    width: 360px;
    height: 560px;
    background-size: 360px 333px;
    background-repeat: no-repeat;
    padding-top: 333px;
    margin-right: 20px;
    @include bg-image('../../assets/image/about-us/who-global');
    box-shadow: 0 5px 30px 0 rgba(73, 86, 117, 0.1);
    border-radius: 2px;

    &>div {
      height: 100%;
      padding-top: 33px;
      background-color: #fff;
    }
  }

  &__speed {
    height: 72px;
    width: 157px;
    margin: 0 auto 12px;
    @include bg-image('../../assets/image/about-us/who-speed');
  }

  &__zeros {
    display: flex;
  }

  &__zero {
    width: 360px;
    height: 261px;
    background-color: #fff;
    border-radius: 2px;
    @include bg-image('../../assets/image/about-us/who-zero-2');
    background-repeat: no-repeat;
    background-size: 103px 72px;
    background-position: 140px 79px;
    position: relative;
    box-shadow: 0 5px 30px 0 rgba(73, 86, 117, 0.1);

    &:first-child {
      margin-right: 20px;
      background-color: #0D73D6;
      @include bg-image('../../assets/image/about-us/who-zero-1');

      span {
        color: rgba(255, 255, 255, .6);
        padding: 0 10px;
      }
    }

    span {
      position: absolute;
      bottom: 76px;
    }
  }

  &__asic {
    margin-top: 20px;
    background-color: #fff;
    width: 740px;
    height: 279px;
    box-shadow: 0 5px 30px 0 rgba(73, 86, 117, 0.1);
    border-radius: 2px;
    @include bg-image('../../assets/image/about-us/who-asic');
    background-repeat: no-repeat;
    background-size: 459px 279px;
    padding-left: 460px;
    position: relative;

    &-pic {
      width: 120px;
      height: 40px;
      @include bg-image('../../assets/image/about-us/who-asic-icon');
      background-size: 120px 40px;
      transform: translate3d(60px, 100px, 0);

      &+span {
        position: absolute;
        bottom: 95px;
        left: 520px;
        width: auto;
      }
    }
  }
}

.info-group__zero-locale {
  &:nth-child(1) {
    @include bg-image('../../assets/image/about-us/who-zero-1-en');
    background-repeat: no-repeat;
    background-size: 168px 72px;
    background-position: 109px 79px;
  }

  &:nth-child(2) {
    @include bg-image('../../assets/image/about-us/who-zero-2-en');
    background-repeat: no-repeat;
    background-size: 168px 72px;
    background-position: 109px 79px;
  }


}

// .full-slider {
//   display: flow-root;

//   .logo {
//     margin: 100px auto 40px auto;
//     width: 191px;
//     display: block;
//   }

//   p {
//     width: 975px;
//     height: auto;
//     background: url(../../assets/img/about/bg2.png) no-repeat center center;
//     border-radius: 10px;
//     overflow: hidden;
//     background-size: 100% 100%;
//     padding: 32px 20px;
//     font-size: 18px;
//     font-weight: bold;
//     line-height: 36px;
//     color: #ffffff;
//     margin: 0 auto;

//   }
// }

.map-container {
  h4 {
    height: 50px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 50px;
    text-align: center;
    margin: 80px 0 38px 0;
  }

  .map {
    width: 800px;
    height: 373px;
    margin: 0 auto 80px;
    @include bg-image('../../assets/image/about-us/who-map');
    position: relative;

    i {
      position: absolute;
      right: 110px;
      bottom: 70px;
      width: 10px;
      height: 10px;
      background-color: #0D73D6;
      border-radius: 50%;
    }

    .map__zero-locale {
      width: 5px;
      height: 5px;
      right: 10vw;
      bottom: 35px;
    }
  }
}

@media screen and (min-width: 992px) and(max-width: 1200px) {
  .info-group__left {
    width: 400px;
    background-position: center top;
  }
}

@media screen and(max-width: 992px) {
  .service {
    width: 80%;
    padding-top: 50px;
    flex-wrap: wrap;
    margin: 0 auto;
    flex-direction: column-reverse;
    gap: 40px;

    h4 {
      font-size: 18px;
    }

    .service_left,
    .service_right {
      width: 100%;
    }

    .service_left {
      .code {
        width: 180px;
        height: 180px;
      }

      .up,
      .down img {
        width: 20px;
      }

      p {
        font-size: 13px;
        line-height: 22px;
      }
    }

    .service_right {
      width: 208px;
      height: auto;

      .top-img {
        width: 45px;
        height: 40px;
      }
    }
  }

  .container {
    width: 100%;
  }

  .banner {
    padding-bottom: 0px;
  }

  .banner__title {
    font-size: 26px;
  }

  .banner__sub-title {
    margin-top: 10px;
  }

  .banner__pics {
    width: 90vw;
    margin: 0 auto;
  }

  .info-group {
    padding: 20px 10px;
    height: 260px;

    .info-group__left {
      height: 220px;
      width: 30%;
      background-size: 100% 110px;
      padding-top: 110px;
      margin-right: 10px;

      >div {
        width: 100%;
        padding-top: 0;

        .info-group__speed {
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
          height: 55px;
          width: 100%;
          margin-bottom: 0;
        }

        span {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }

    .info-group__right {
      width: calc(70% - 10px);
      height: 220px;

      .info-group__zero {
        width: calc((100% - 10px)/2);
        height: 105px;
        margin-right: 10px;
        background-size: 40%;
        background-position: center 10px;

        &:nth-child(2) {
          margin-right: 0;
        }

        span {
          font-size: 13px;
          top: 50%;
        }
      }

      .info-group__asic {
        margin-top: 10px;
        height: 105px;
        width: 100%;
        padding-left: 0;
        background-size: 50%;
        background-position: left center;
        position: relative;

        .info-group__asic-pic {
          width: 45px;
          height: 15px;
          position: absolute;
          right: 20px;
          top: 30px;
          background-repeat: no-repeat;
          background-size: cover;
          transform: none;
        }

        span {
          position: absolute;
          right: 20px;
          top: 50px;
          left: inherit;
          bottom: inherit;
          font-size: 13px;
          color: #000;
          display: block;
          height: 20px;
          width: 120px;
          text-align: right;
        }
      }
    }
  }



  .map-container {
    width: 100vw;

    h4 {
      min-height: 40px;
      height: 30px;
      font-size: 26px;
      color: #000;
      line-height: 30px;
      margin: 0;
      text-align: center;
      margin-top: 20px;
    }

    .map {
      width: 90%;
      height: 170px;
      background-size: cover;
      background-position: center center;
    }
  }

}
</style>