<template>
  <div>
    <div class="header-slider">
      <home-header v-if="screenWidth > 992"></home-header>
      <div class="container">
        <div class="breadcrumb">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">{{$t("header.home")}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{$t("header.community")}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ sliderText.title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="about-us">
          <h4>{{ sliderText.title }}</h4>
          <p>{{ sliderText.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import HomeHeader from '@/components/header/header';

  export default {
    name: 'community-slider',

    components: {
      HomeHeader,
    },

    data() {
      return {

      }
    },

    computed: {
      screenWidth() {
        let width = localStorage.getItem('screen_width') || 1200
        console.log(width)
        return width
      },
      sliderText() {
        const { path } = this.$route;
        const defaultInfo = {
          title: this.$t("header.followingUp"),
          content: this.$t("deal.service")
        }
        const pathMapping = {
          '/community': defaultInfo,
          '/phone/community': defaultInfo,
          '/community/followingUp': {
            title: this.$t("header.followingUp"),
            content: this.$t("community.followingUp.content")
          },
          '/phone/community/followingUp': {
            title: this.$t("header.followingUp"),
            content: this.$t("community.followingUp.content")
          },
          '/community/multiAccountManagement': {
            title: this.$t("header.multiAccountManagement"),
            content: this.$t("community.multiAccountManagement.content")
          },
          '/phone/community/multiAccountManagement': {
            title: this.$t("header.multiAccountManagement"),
            content: this.$t("community.multiAccountManagement.content")
          }
        }
        return pathMapping[path] || defaultInfo;
      }
    },
  }
</script>

<style scoped lang="scss">
  .header-slider {
    display: flow-root;
    // height: 440px;
    padding-bottom: 60px;
    background-size: cover;
    @include bg-image('../../assets/image/community/slider-bg');

    .breadcrumb {
      margin-top: 88px;
      /deep/ .el-breadcrumb__inner {
        font-size: 16px;
        color: rgba(255, 255, 255, .6);
      }
      /deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner {
        color: #3A93FF;
      }
    }

    .about-us {
      margin-top: 80px;
      h4 {
        font-size: 40px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 56px;
      }
      p {
        margin-top: 14px;
        max-width: 1000px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.66);
        line-height: 22px;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .header-slider{
      padding-bottom: 0px;
    }
    .container{
      width: 100vw;
      min-height: 250px;
      height: auto;
      overflow: hidden;
      padding: 10px 15px;
      padding-top: 45px;
      .breadcrumb{
        margin-top: 28px;
      }
      .about-us{
        margin-top: 20px;
        h4{
          font-size: 20px;
        }
        p{
          font-size: 14px;
          max-width: 100%;
          white-space: normal;
          margin-top: 0;
        }
      }
    }
  }
</style>