<template>
  <div class="chart" ref="chartRef"></div>
</template>

<script>
import * as echarts from 'echarts'
import _ from 'lodash'
import { color, getDefaultChartOption } from '@/utils/chart'

export default {
  name: 'table-cell-chart',

  props: {
    chartData: {
      type: Array
    }
  },

  watch: {
    chartData: {
      handler(value) {
        value && value.length && this.updateChart()
      },
      immediate: true
    }
  },

  data() {
    return {
      chartRef: 'chartRef',
      chart: null,
      option: null
    }
  },

  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs[this.chartRef])
      this.mergeBaseChartOption()
    },

    mergeBaseChartOption() {
      this.option = _.merge(getDefaultChartOption(), {
        grid: {
          left: -5,
          right: -5,
          top: 1,
          bottom: 1
        },
        yAxis: {
          min: val => val.min,
          axisLabel: {
            show: false
          },
          splitLine: { show: false }
        },
        xAxis: {
          show: false,
          axisLabel: {
            show: false
          },
          splitLine: { show: false }
        },
        axisPointer: { show: false },
        tooltip: { show: false },
        color: '#0D73D6'
      })
    },

    updateChart() {
      if (this.chartData && this.chartData.length && this.option) {
        this.xAxisData = this.chartData.map(d => d.time)
        this.dataSet = this.chartData.map(d => d.data)

        this.option.xAxis.data = this.xAxisData
        this.option.series = this.getSeries()

        this.chart.setOption(this.option)
      }
    },

    getSeries() {
      return [
        {
          type: 'line',
          symbol: 'none',
          smooth: true,
          data: this.dataSet,
          lineStyle: {
            width: 0.8
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(13,115,214, 0.6)'
                },
                {
                  offset: 0.5,
                  color: 'rgba(13,115,214, 0.1)'
                },
                {
                  offset: 1,
                  color: 'transparent' // 100% 处的颜色
                }
              ]
            }
          }
        }
      ]
    }
  },

  mounted() {
    this.initChart()
    this.$nextTick(() => {
      this.updateChart()
    })
  }
}
</script>

<style scoped lang="scss">
.chart {
  width: 220px;
  height: 46px;
  margin: 0 auto;
}
</style>
