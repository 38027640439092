<template>
  <div class="warpper">
    <DealBan :title="title"></DealBan>
    <hottest class="hot-forex" :title="$t('deal.forex.title10')" :apiCodes="['USOIL', 'UKOIL']">
    </hottest>
    <div class="currency-rule">
      <h4>{{ $t("deal.forex.title5") }}</h4>
      <CurrencyRule :source="currencyRule" />
    </div>
    <div class="other-product">
      <h4>{{ $t("deal.forex.title4") }}</h4>
      <OtherProduct :source="otherProduct" />
    </div>
  </div>
</template>

<script>
import CurrencyRule from './currency-rule'
import OtherProduct from './other-product'
import Hottest from '../hottest'
import DealBan from './DealBan.vue';

export default {
  name: 'Forex',
  components: {
    CurrencyRule,
    OtherProduct,
    Hottest,
    DealBan
  },
  data() {
    return {
      time: ['06', '08', '10', '12', '14', '16', '18', '20', '22', '24', '02', '04', '06'],
      otherProduct: '/deal/energy',
      // title: this.$t("deal.title2")
    }
  },
  computed: {
    title() {
      return this.$t('deal.title2');
    },
    currencyRule() {
      return [
        {
          symbols: 'USOil',
          contract: 1000,
          digits: 3,
          minimum: 0.01,
          maximun: 30,
          step: 0.01,
          leverage: '1:200'
        },
        {
          symbols: 'UKOil',
          contract: 1000,
          digits: 3,
          minimum: 0.01,
          maximun: 30,
          step: 0.01,
          leverage: '1:200'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.warpper {
  h4 {
    font-family: MicrosoftYaHeiUI-Bold;
    font-size: 50px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    padding: 100px 0;
  }
}

.hot-warpper {
  width: 1120px;
  margin: 0 auto;
  display: flex;

  .item {
    flex: 1;
    box-shadow: 0px 0px 20px 0px rgba(33, 66, 171, 0.1500);
    border-radius: 2px;
    margin-right: 20px;
    padding: 24px;
    box-sizing: border-box;

    &:last-child {
      margin-right: 0;
    }

    .list {
      flex: 1;

      &.price p:nth-child(2) {
        color: #000;
      }

      &.day p:nth-child(2) {
        color: #F85247;
      }

      &.week p:nth-child(2) {
        color: #F85247;
      }
    }

    .tit {
      font-size: 20px;
      font-weight: 600;
      color: #000000;
    }

    .data {
      display: flex;
      color: #000;
      margin-top: 22px;

      .subTit {
        color: rgba($color: #000000, $alpha: 0.6);
      }

      .num {
        margin-top: 10px;
      }
    }

    .echarts {
      height: 100px;
      color: #000;
      margin-bottom: 12px;
      margin-top: 27px;
    }
  }
}

.hot-forex {
  padding-top: 1px;
}

.currency-rule {
  padding-top: 1px;
}

.other-product {
  padding-bottom: 200px;
  padding-top: 1px;
}

@media screen and(max-width: 992px) {
  .hot-forex {
    padding-bottom: 20px;
  }

  .other-hot-forex,
  .currency-rule,
  .other-product {
    padding-bottom: 20px !important;

    h4 {
      font-family: MicrosoftYaHeiUI-Bold;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      padding: 30px 0;
      box-sizing: content-box;
    }
  }
}
</style>