<template>
  <div>
    <SupportBan :title="title"></SupportBan>
    <AgentInvite />
    <RegisterProcess   />
     
  </div>
</template>

<script>
import AgentInvite from './agent-invite'
import RegisterProcess from './register-process'
import RegisterProcessPhone from "./register-process-phone";
import SupportBan from "@/components/support/SupportBan";

export default {
  components: {
    RegisterProcessPhone,
    AgentInvite,
    RegisterProcess,
    SupportBan
  },
  props: {},
  data() {
    return {
      // title: this.$t("header.nationalBroker")
    }
  },
  watch: {},
  computed: {
    title() {
      return this.$t('header.nationalBroker');
    },
    screenWidth() {
      let width = localStorage.getItem('screen_width') || 1200
      console.log(width)
      return width
    },
  },
  methods: {},
  created() { },
  mounted() { }
}
</script>
<style scoped></style>