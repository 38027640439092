<template>
  <div class="container footer-nav">
    <li class="nav-first-phone">
      <img src="@/assets/logo.svg" alt="logo" />
      <p class="nav-tip">{{ $t("newest.foot_tip8") }}</p>
      <p class="nav-tip">{{ $t("newest.foot_tip9") }}</p>
    </li>

    <ul class="nav-list flex flex__justify-space-between">
      <li class="nav-first">
        <img src="@/assets/logo.svg" alt="logo" width="200" />
        <p class="nav-tip">{{ $t("newest.foot_tip8") }}</p>
        <p class="nav-tip">{{ $t("newest.foot_tip9") }}</p>
      </li>
      <li class="nav-list-list flex flex-column">
        <h5 class="nav-list-item">{{ $t("newest.foot_tip11") }}</h5>
        <span class="nav-list-item" @click.stop="goTo('/about-us/who')">{{ $t("newest.foot_tip14") }}</span>
        <span class="nav-list-item" @click.stop="goTo('/about-us/contact')">{{ $t("header.contactUs") }}</span>
        <span class="nav-list-item" @click.stop="goTo('/partnership')">{{ $t("newest.part_tip1") }}</span>
        <!-- <span class="nav-list-item" @click.stop="goTo('/about-us/advantage')">{{ $t("header.ourAdvantages") }}</span> -->
        <!--        <span class="nav-list-item">{{$t("header.agencyCooperation")}}</span>-->
      </li>
      <li class="nav-list-list flex flex-column">
        <h5 class="nav-list-item" @click.stop="goTo('/platform')">{{ $t("newest.foot_tip12") }} MT4</h5>
        <span class="nav-list-item" @click="$open('pc')">{{ $t("newest.foot_tip15") }}</span>
        <span class="nav-list-item" @click="$open('mac')">{{ $t("newest.foot_tip16") }}</span>
        <span class="nav-list-item" @click="$open('android')">{{ $t("newest.foot_tip17") }}</span>
        <span class="nav-list-item" @click="$open('iOS')">{{ $t("newest.foot_tip18") }}</span>

      </li>
      <!-- <li class="nav-list-list flex flex-column">
        <h5 class="nav-list-item" @click="goTo('/deal')">{{ $t("header.product") }}</h5>
        <span class="nav-list-item" @click.stop="goTo('/deal/forex')">{{ $t("header.foreignExchange") }}</span>
        <span class="nav-list-item" @click.stop="goTo('/deal/metal')">{{ $t("newest.home_tip11") }}</span>
        <span class="nav-list-item" @click.stop="goTo('/deal/energy')">{{ $t("header.energy") }}</span>
        <span class="nav-list-item" @click.stop="goTo('/deal/exponent')">{{ $t("newest.home_tip5") }}</span>
        <span class="nav-list-item" @click.stop="goTo('/deal/DigitalCurrency')">{{ $t("newest.home_tip13")
          }}</span>
         
      </li> -->
      <li class="nav-list-list flex flex-column">
        <h5 class="nav-list-item">{{ $t("newest.foot_tip13") }}</h5>

        <span class="nav-list-item">richsmart@richsmart.net</span>
        <span class="nav-list-item">{{ $t("newest.foot_tip10")
          }}</span>
      </li>
      <!-- <li class="nav-list-list flex flex-column">
        <h5 class="nav-list-item" @click="goTo('/activity')">{{ $t("header.activity") }}</h5>
       
      </li>
      <li class="nav-list-list flex flex-column">
        <h5 class="nav-list-item" @click="goTo('/notice')">{{ $t("header.notice") }}</h5>
       
      </li> -->
      <!--      <li class="nav-list-list flex flex-column">-->
      <!--        <h5 class="nav-list-item">{{$t("header.notice")}}</h5>-->
      <!--        <span class="nav-list-item">Web&nbsp;Trader</span>-->
      <!--        <span class="nav-list-item">{{$t("header.energy")}}</span>-->
      <!--        <span class="nav-list-item">{{$t("header.investor")}}</span>-->
      <!--        <span class="nav-list-item">Tools</span>-->
      <!--        <span class="nav-list-item">{{$t("header.signIn")}}</span>-->
      <!--      </li>-->
      <!-- <li class="nav-list-list flex flex-column">
        <h5 class="nav-list-item" @click="goTo('/support')">{{ $t("header.support") }}</h5>
        <span class="nav-list-item" @click.stop="goTo('/support/agent')">{{ $t("header.nationalBroker") }}</span>
        <span class="nav-list-item" @click.stop="goTo('/support/fund')">{{ $t("header.outOfGoldIntoGold") }}</span>
        <span class="nav-list-item" @click.stop="goTo('/support/faq')">{{ $t("header.questionAnswering") }}</span>
               <span class="nav-list-item">{{$t("header.commonProblem")}}</span>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: 'footer-nav',
  methods: {
    goTo(path) {
      this.$router.push({ path });

    }
  }
}
</script>

<style scoped lang="scss">
.nav-tip {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  padding-top: 18px;
}

.nav-list-list {
  flex: 1;
}

.footer-nav {
  padding-top: 60px;
  padding-bottom: 40px;
  border-bottom: 1px solid #cccccc;

  .nav-list {
    gap: 30px;

    h5 {
      font-size: 16px;
      font-weight: normal;
      color: #fff;
      font-weight: bold;


    }

    span {
      margin-top: 24px;
      font-size: 14px;
      color: #999;
    }

    .nav-list-item {
      cursor: pointer;

      &:hover {
        color: var(--button-primary-bg);
      }
    }
  }
}

.nav-first {
  max-width: 400px;
}

.nav-first-phone {
  display: none;
}

@media screen and (max-width:768px) {

  .nav-first-phone {
    display: block;
    padding: 20px 0;
    font-size: 14px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }

  .nav-tip {
    font-size: 13px;
  }

  .nav-first {
    display: none;
  }

  .footer-nav {
    padding: 20px 0;
  }

  .container {
    width: 90%;
    margin: 0 auto;
  }

  .footer-nav .nav-list h5 {
    font-size: 14px;
  }

  .footer-nav .nav-list span {
    font-size: 11px;
    margin-top: 18px;
  }



}
</style>
