<template>
  <div id="app" :class="{ 'app-phone': screenWidth <= 992 }" v-if="load">
    <HeaderNew></HeaderNew>
    <router-view />
    <home-footer v-if="screenWidth > 992"></home-footer>
    <!--    <home-footer-phone v-else></home-footer-phone>-->
  </div>
</template>

<script>
import HeaderNew from '@/components/header/HeaderNew'
import HomeFooter from '@/components/footer/footer';
import HomeFooterPhone from './components/footer/footerPhone'

export default {
  components: {
    HomeFooterPhone,
    HomeFooter,
    HeaderNew,
  },
  data() {
    return {
      screenWidth: 0,
      load: false
    }
  },
  watch: {
    '$route': {
      handler(v) {
        if (document.body.clientWidth >= 992) {
          document.documentElement.scrollTop = document.body.scrollTop = 0;
        }
      },
      deep: true
    }
  },
  created() {
    let that = this;
    window.onload = function () {
      let width = document.body.clientWidth;
      that.screenWidth = width
      localStorage.setItem('screen_width', width);
      that.load = true
    }

  }
}
</script>
<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
  background: #000;
  color: #fff;
  font-family: MicrosoftYaHeiUI;
  font-size: 14px;
}

#app {
  max-width: 1920px;
  margin: 0 auto;
  // background: var(--theme-bg);
}

.app-phone {
  overflow: hidden;
}

.common-but {
  min-width: 202px;
  height: 58px;
  line-height: 58px;
  text-align: center;
  background-color: #132148;
  box-shadow: 5px 8px 23px 4px rgba(201, 152, 89, 0.15);
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  padding: 0 30px;
  cursor: pointer;
  border: none;
  outline: none;
}

@media screen and (max-width: 992px) {
  .common-but {
    min-width: 102px;
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    padding: 0 20px;
    border-radius: 5px;
    border: 1px solid #fff;
  }
}

.el-table__header-wrapper {
  text-align: center;
  background-color: transparent;
}

.el-table {
  background-color: transparent !important;
}

table {
  margin-block-end: 0 !important;
  -webkit-margin-after: 0 !important;
}
</style>
