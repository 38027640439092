<template>
  <div>

    <!-- <header-slider /> -->
    <div class="sub-page">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import HeaderSlider from '@/components/support/slider';
export default {
  name: 'Support',
  components: {
    HeaderSlider
  }
}
</script>

<style scoped lang="scss">
.sub-page {
  /deep/ .el-breadcrumb__item {
    font-size: 16px;
  }

  /deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #000;
  }
}
@media screen and (max-width: 992px) {
  .sub-page{
    margin-top: 0;
    padding-top: 64px;
  }
}
</style>