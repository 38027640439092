<template>
  <div class="sub-page">
    <DownloadBan></DownloadBan>
    <!-- <header-slider /> -->
    <router-view></router-view>
  </div>
</template>

<script>
import HeaderSlider from '@/components/platform/slider';
import DownloadBan from "@/components/platform/DownloadBan";
export default {
  name: 'Deal',
  components: {
    HeaderSlider,
    DownloadBan
  },
  data() {
    return {

    }
  }
}

</script>

<style scoped lang="scss">
.sub-page {
  /deep/ .el-breadcrumb__item {
    font-size: 16px;
  }

  /deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #000;
  }
}

@media screen and (max-width: 992px) {
  .sub-page {
    margin-top: 0;
    padding-top: 64px;
  }
}
</style>