<template>
  <div class="fund-question">
    <!-- <h3>{{ $t('support.fundQuestion.title') }}</h3> -->
    <img src="../../assets/img/support/qa.png" class="tit-img" />
    <div class="warpper">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item v-for="item in collapse" :key="item.name" :name="item.name">
          <template slot="title">
            <span :class="[activeName === item.name ? 'collapse-title-active' : 'collapse-title']">{{ item.des }}</span>
          </template>
          <div class="con">{{ item.content }}</div>
        </el-collapse-item>
      </el-collapse>
      <div class="buts">
        <div class="common-but" @click="goTo('/support/faq')">Learn more</div>
      </div>

    </div>
  </div>
</template>

<script>
import { method } from 'lodash';

export default {
  components: {},
  data() {
    return {
      activeName: '1'
    }
  },
  methods: {
    goTo(path) {
      this.$router.push({ path });

    }
  },
  computed: {
    collapse() {
      return [
        {
          name: '1',
          des: this.$t('support.fundQuestion.des11'),
          content: this.$t('support.fundQuestion.content11')
        }, {
          name: '2',
          des: this.$t('support.fundQuestion.des12'),
          content: this.$t('support.fundQuestion.content12')
        }, {
          name: '3',
          des: this.$t('support.fundQuestion.des13'),
          content: this.$t('support.fundQuestion.content13')
        }
        // {
        //   name: '1',
        //   des: this.$t('support.fundQuestion.des1'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '2',
        //   des: this.$t('support.fundQuestion.des2'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '3',
        //   des: this.$t('support.fundQuestion.des3'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '4',
        //   des: this.$t('support.fundQuestion.des4'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '5',
        //   des: this.$t('support.fundQuestion.des5'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '6',
        //   des: this.$t('support.fundQuestion.des6'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '7',
        //   des: this.$t('support.fundQuestion.des7'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '8',
        //   des: this.$t('support.fundQuestion.des8'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '9',
        //   des: this.$t('support.fundQuestion.des9'),
        //   content: this.$t('support.fundQuestion.content')
        // },{
        //   name: '10',
        //   des: this.$t('support.fundQuestion.des10'),
        //   content: this.$t('support.fundQuestion.content')
        // },
      ]
    }
  }
}
</script>
<style scoped lang="scss">
.tit-img {
  display: block;
  width: 223px;
  margin: 100px auto 40px auto;
}

.buts {
  margin: 20px 0;
}

.fund-question {
  padding-top: 80px;
  padding-bottom: 110px;

  h3 {
    font-size: 36px;
    font-weight: bold;
    color: #0B0B0B;
    line-height: 50px;
    text-align: center;
  }

  .warpper {
    width: 1350px;
    height: 474px;
    padding: 42px;
    box-sizing: border-box;
    box-shadow: 0px 0px 21px 0px rgba(201, 152, 89, 0.52);
    border-radius: 10px;
    border: solid 1px #132148;
    margin: 0 auto;

    .collapse-title {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      line-height: 22px;
    }

    .collapse-title-active {
      font-size: 16px;
      font-weight: 400;
      color: #0D73D6;
      line-height: 22px;
    }

    .con {
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      line-height: 24px;
    }
  }
}

/deep/ .el-collapse {
  border: none;
}

/deep/.el-collapse-item {
  margin-bottom: 6px;
}

/deep/ .el-collapse-item__header {
  border-bottom: none;
  background: transparent;
  color: #fff;
  line-height: 70px;
  height: 70px;
  font-size: 18px;
  font-weight: bold;
}

/deep/ .el-collapse-item__wrap {
  background: transparent;
  border-bottom: none;
}

/deep/ .el-collapse-item__header.is-active {
  border-bottom: 1px solid #999999;
}

/deep/ .el-collapse-item__content {
  padding-top: 20px;
  padding-bottom: 36px;
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
}

@media screen and (min-width: 1200px) {}



@media screen and (max-width: 992px) {
  .fund-question {
    padding-top: 20px;
    padding-bottom: 20px;

    h3 {
      width: 100%;
      height: 22.5px;
      line-height: 22.5px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .tit-img {
      width: 180px;
      margin: 50px auto 20px auto;
    }

    .warpper {
      width: 95vw;
      padding: 20px;

      .collapse-title {
        font-size: 12px;
      }

      .collapse-title-active {
        font-size: 12px;
      }

      .con {
        font-size: 11px;
        line-height: 20px;
      }

      .common-but {
        height: 36px;
        line-height: 36px;
      }
    }
  }
}
</style>