<template>
  <div>
    <SupportBan :title="title"></SupportBan>
    <FundInfo />
    <FundTable />
    <FundQuestion />
  </div>
</template>

<script>
import SupportBan from "@/components/support/SupportBan";
import FundInfo from './fund-info'
import FundTable from './fund-table'
import FundQuestion from './fund-question'
import FundInfoPhone from "./fund-info-phone";
export default {
  components: {
    SupportBan,
    FundInfoPhone,
    FundInfo,
    FundTable,
    FundQuestion
  },
  data() {
    return {
      // title:this.$t("header.outOfGoldIntoGold")
    }
  },

  computed: {
    title() {
      return this.$t('header.outOfGoldIntoGold');
    },
    screenWidth() {
      let width = localStorage.getItem('screen_width') || 1200

      return width
    },
  }
}
</script>
<style scoped></style>