<template>
    <div>
        <div class="desktop box">
            <h4>{{$t("community.followingUp.title")}}</h4>
            <img src="@/assets/image/community/following-up-en.png" v-if="$i18n.locale == 'en'">
            <img src="@/assets/image/community/following-up.png" v-else>
            <p>{{$t("community.followingUp.service")}}</p>
            <button class="btn btn-primary" >{{$t("common.registerNow")}}</button>
        </div>
        <div class="tab">
            <ul>
                <li>
                    <p>
                        <img src="@/assets/image/community/following-up-tab-1.png">
                    </p>
                    <span v-html="$t('community.followingUp.tab1')"></span>
                </li>
                <li>
                    <p>
                        <img src="@/assets/image/community/following-up-tab-2.png">
                    </p>
                    <span v-html="$t('community.followingUp.tab2')"></span>
                </li>
                <li>
                    <p>
                        <img src="@/assets/image/community/following-up-tab-3.png">
                    </p>
                    <span v-html="$t('community.followingUp.tab3')"></span>
                </li>
                <li>
                    <p>
                        <img src="@/assets/image/community/following-up-tab-4.png">
                    </p>
                    <span v-html="$t('community.followingUp.tab4')"></span>
                </li>
                <li>
                    <p>
                        <img src="@/assets/image/community/following-up-tab-5.png">
                    </p>
                    <span v-html="$t('community.followingUp.tab5')"></span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "view-following-up-phone"
    }
</script>

<style scoped lang="scss">
    .box{
        width: 100vw;
        height: auto;
        padding: 20px 15px;
        text-align: center;
        h4{
            width: 100%;
            height: 22.5px;
            line-height: 22.5px;
            text-align: center;
            color: #000000;
            font-size: 20px;
            font-weight: 600;
        }
        p {
            width: 100%;
            height: auto;
            font-size: 13px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.8);
            line-height: 20px;
            margin-top: 10px;
        }
        img{
            width: 75%;
            height: auto;
            margin-top: 20px;
            object-fit:contain ;
        }
        .btn{
            height: 30px;
            line-height: 30px;
            margin: 20px 2px 0 2px;
        }
    }
    .tab{
        width: 100vw;
        padding: 20px 15px;
        li{
            width: 100%;
            height: 100px;
            background-color: #fff;
            box-shadow: 0px 2px 15px 0px rgba(73,86,117,0.4);
            border-radius: 5px;
            margin-bottom: 15px;
            line-height: 100px;
            color: #000;
            font-size: 16px;
            p{
                float: right;
                width: 50px;
                height: 50px;
                margin: 25px 30px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            span{
                padding-left: 30px;
            }
            &:nth-child(2n) {
                p{
                    float: left;
                }
                span{
                    padding-left: 0;
                }
            }
        }
    }
</style>