<template>
    <div class="forex-box">
        <div class="forex-chart">
            <div class="forex-chart-head">
                <div class="head-title">
                    <div class="title">{{ apiCode }}</div>
                    <div :class="['index', dayWave]">
                        {{ price }}
                        <span>
                            {{ day }}
                            <i class="el-icon-top-right" v-show="dayWave === 'increase'"></i>
                            <i class="el-icon-bottom-right" v-show="dayWave === 'decline'"></i>
                        </span>
                    </div>
                </div>
                <div class="head-item">
                    <div class="item" v-for="item in accuracys" :key="item"
                        :class="{ 'item-active': item == accuracysActive }" @click="accuracysActive = item">{{ item }}
                    </div>
                </div>
            </div>
            <div class="forex-chart-body">
                <div class="chart" ref="chartRef"></div>
            </div>
            <div class="forex-chart-foot">
                <p>
                    <span>Spreads(%)</span>
                    <span>Spreads/Lot</span>
                    <span>Swap Rate(Buy)</span>
                    <span>Swap Rate(Sell)</span>
                    <span>Platform Trading Hours(GMT+3)</span>
                    <span>Local Trading Hours(GMT+8)</span>
                </p>
                <p>
                    <span>68.08835</span>
                    <span>0.5</span>
                    <span>-1.44</span>
                    <span>-1.21</span>
                    <span>00:00-24:00(Mon-Fri)</span>
                    <span>05:00-05:00(Mon-Fri)</span>
                </p>
            </div>
        </div>
    </div>

</template>

<script>
import * as echarts from 'echarts'
import { nowOffsetTime } from '@/utils/dateUtils'
import { returnFloat } from '@/utils/common'
import moment from 'moment'
import { GbpService } from '@/service/gbp'
const gbpService = new GbpService()

export default {
    name: 'forex-gpd-chart',
    props: {
        apiCode: {
            type: String,
            default: () => ''
        }
    },
    data() {
        return {
            price: '',
            day: '',
            dayStart: '',
            dayWave: '',
            list: [],
            accuracysActive: 'M1',
            accuracys: ['M1', 'M5', 'M15', 'M30', 'H1', 'H4', 'D1', 'W1', 'MN'],
            queryInfoMapping: {
                M1: { type: 'DAY', period: 1, pastTimes: 60 * 80 * 4 },
                M5: { type: 'DAY', period: 5, pastTimes: 60 * 5 * 80 * 4 },
                M15: { type: 'DAY', period: 15, pastTimes: 60 * 15 * 80 * 4 },
                M30: { type: 'DAY', period: 30, pastTimes: 60 * 30 * 80 * 4 },
                H1: { type: 'WEEK', period: 60, pastTimes: 60 * 60 * 80 * 4 },
                H4: { type: 'WEEK', period: 240, pastTimes: 60 * 60 * 4 * 80 * 4 },
                D1: { type: 'WEEK', period: 1440, pastTimes: 60 * 60 * 24 * 80 * 4 },
                W1: { type: 'MONTH', period: 10080, pastTimes: 60 * 60 * 24 * 7 * 80 * 4 },
                MN: { type: 'MONTH', period: 43200, pastTimes: 60 * 60 * 24 * 30 * 80 * 4 }
            },
            period: 1,
            chartRef: 'chartRef',
            chart: null,
            symbolTimer: null
        }
    },
    watch: {
        accuracysActive() {
            this.period = this.queryInfoMapping[this.accuracysActive].period
            clearTimeout(this.symbolTimer)
            this.init()
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            let that = this;
            Promise.resolve(this.getWeekOpen()).then(() => {
                clearTimeout(that.symbolTimer)
                that.getPriceBySymbol()
            })
        },
        async getPriceBySymbol() {
            let _self = this;
            let params = {
                symbol_array: [this.apiCode]
            }
            await gbpService.getPriceBySymbol(params).then(data => {
                _self.price = returnFloat(data[0].BID, this.apiCode)
                _self.day = (Math.abs((data[0].BID - this.dayStart) / this.dayStart) * 100).toFixed(2) + '%'
                _self.dayWave = data[0].BID - this.dayStart > 0 ? 'increase' : 'decline'
            })
        },
        async getWeekOpen() {
            let nowTime = nowOffsetTime(10)
            await gbpService
                .getHistoryRatesInfo({
                    reqtype: 'historyratesinfo',
                    reqid: '100',
                    symbol: this.apiCode,
                    period: this.period,
                    fromtime: nowTime - 24 * 60 * 60,
                    endtime: nowTime
                })
                .then(res => {
                    if (!res[0]) return

                    let today = new Date().getDate()
                    let todayIndex = 0
                    res.forEach((data, idx) => {
                        if (!todayIndex && today === data.date) {
                            todayIndex = idx
                        }
                    })
                    const [{ data: dayStart }] = res.slice(todayIndex, todayIndex + 1)
                    this.dayStart = returnFloat(dayStart[0], res[0].symbol)
                    this.list = res;
                    this.initChart(res)
                })
        },
        initChart(data) {
            this.chart = echarts.init(this.$refs[this.chartRef])
            this.mergeBaseChartOption(data)
        },
        mergeBaseChartOption(data) {
            let _this = this
            let xAxisData = [], seriesData = [];
            data.forEach(x => {
                xAxisData.push(moment(new Date(Number(x.time))).format('HH:mm'))
                seriesData.push(x.data)
            })
            this.option = {
                grid: {
                    left: '20px',
                    top: '20px',
                    right: '20px',
                    bottom: '12%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    },
                    formatter: function (params) {
                        let name = ['open', 'close', 'lowest', 'highest']
                        let str = `<span style="color: #000000;font-weight: 600">${_this.apiCode}</span>` + "<br/>" +
                            `<span style="display:block;color: rgba(0,0,0,0.6);">${moment(new Date(Number(_this.list[params[0].dataIndex].time))).format('yyyy-MM-DD HH:mm')}</span>` + "<br/>"
                        for (let i = 0; i < 4; i++) {
                            str += `<span style="display:block;color: #000000;text-align: right;line-height: 16px;margin: 0;padding: 0"><label style="float: left;color: rgba(0,0,0,0.6)">${name[i]}</label> ${_this.list[params[0].dataIndex].data[i]}</span>` + "<br/>"
                        }

                        return str
                    }
                },
                dataZoom: [
                    {
                        type: 'inside',
                        start: 50,
                        end: 100
                    },
                    {
                        show: true,
                        type: 'slider',
                        top: '90%',
                        start: 90,
                        end: 100
                    }
                ],
                xAxis: {
                    data: xAxisData,
                },
                yAxis: {
                    scale: true,
                    position: 'right'
                },
                series: [
                    {
                        type: 'candlestick',
                        data: seriesData
                    }
                ]
            }
            this.chart.setOption(this.option)
            window.addEventListener("resize", () => {
                this.chart.resize();
            });
            this.symbolTimer = setTimeout(() => {
                this.init()
            }, 5000)
        }
    }
}
</script>

<style scoped lang="scss">
.forex-box {
    width: 100%;
    height: auto;
    background-color: #F5F6F8;
    /*padding-top: 80px;*/
}

.forex-chart {
    width: 1120px;
    height: 630px;
    background: #000;
    box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.3500);
    border-radius: 6px;
    margin: 0 auto;

    .forex-chart-head {
        height: 60px;
        width: 100%;
        padding: 10px 30px 0 30px;

        .head-title {
            float: left;

            .title {
                height: 33px;
                line-height: 33px;
                font-size: 24px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #000000;
            }

            .index {
                height: 25px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 25px;

                span {
                    color: #000000;
                    margin-left: 20px;
                }
            }

            .increase {
                span {
                    color: var(--info);
                }
            }

            .decline {
                span {
                    color: var(--error);
                }
            }
        }

        .head-item {
            float: right;
            height: 60px;
            line-height: 60px;

            .item {
                float: left;
                margin-left: 34px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.6);
                cursor: pointer;
            }

            .item-active {
                color: #2A5BD6;
            }
        }
    }

    .forex-chart-body {
        width: 100%;
        height: 480px;
        padding: 10px 30px;

        .chart {
            width: 100%;
            height: 100%;
        }
    }

    .forex-chart-foot {
        width: 100%;
        height: 97px;
        background-color: #000;
        padding: 20px 30px;
        overflow-y: auto;

        p {
            width: 100%;
            height: 50%;
            display: flex;
            line-height: 28px;

            span {
                flex: 1;
                text-indent: 5px;

                &:nth-child(5) {
                    flex: 2;
                }

                &:nth-child(6) {
                    flex: 2;
                }
            }

            &:nth-child(1) {
                span {
                    font-size: 14px;
                    color: #fff;
                }
            }

            &:nth-child(2) {
                span {
                    font-size: 18px;
                    font-weight: 600;
                    color: #fff;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and(max-width: 1200px) {
    .forex-chart {
        width: calc(100vw - 60px);
    }
}

@media screen and(max-width: 992px) {
    .forex-chart {
        width: 100vw;
        height: 500px;
        border-radius: 0;

        .forex-chart-head {
            height: auto;
            overflow: hidden;

            .head-title {
                width: 100%;
            }

            .head-item {
                float: left;
                height: 30px;
                line-height: 30px;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.1);
                display: flex;
                border-radius: 5px;
                margin-top: 5px;

                .item {
                    flex: 1;
                    margin-left: 0;
                    font-size: 14px;
                    text-align: center;
                }
            }
        }

        .forex-chart-body {
            height: 340px;

        }

        .forex-chart-foot {
            height: 60px;
            padding: 10px 15px;

            p {
                line-height: 20px;
                width: 992px;

                span {
                    text-indent: 2px;
                    font-size: 14px !important;
                }
            }

        }
    }

}
</style>