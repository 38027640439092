import axios from 'axios'

// const baseURL = process.env.NODE_ENV === 'production' ? '/mt4' : '/api'

// axios.defaults.baseURL = baseURL

export function get(url, query = {}) {
  return axios
    .get(url, {
      params: {
        ...query,
      },
    })
    .then((res) => {
      return res
    })
    .catch((e) => {
      console.error(e)
      return e
    })
}

export function post(url, query = {}) {
  return axios
    .post(url, query)
    .then((res) => {
      return res
    })
    .catch((e) => {
      console.error(e)
      return e
    })
}
