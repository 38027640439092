<template>
  <div class="warpper">
    <el-table :data="source" stripe>
      <el-table-column prop="symbols" :label="$t('deal.columns[0]')">
      </el-table-column>
      <el-table-column prop="contract" :label="$t('deal.columns[1]')">
      </el-table-column>
      <el-table-column prop="digits" :label="$t('deal.columns[2]')">
      </el-table-column>
      <el-table-column prop="minimum" :label="$t('deal.columns[3]')">
      </el-table-column>
      <el-table-column prop="maximun" :label="$t('deal.columns[4]')">
      </el-table-column>
      <el-table-column prop="step" :label="$t('deal.columns[5]')">
      </el-table-column>
      <el-table-column prop="leverage" align="center" :label="$t('deal.columns[6]')">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    source: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      otherHotForex: [{
        name: 'EURUSD欧元兑美元',
        price1: '最高500',
        price2: '120欧元',
        contract: '10万单位基本货币',
        point: '10美元',
        platformTime: '00:01~23:59 周五00:01~23:55',
        bjTime: '06:01~次日05:59 周五06:01~05:55'
      }, {
        name: 'EURUSD欧元兑美元',
        price1: '最高500',
        price2: '120欧元',
        contract: '10万单位基本货币',
        point: '10美元',
        platformTime: '00:01~23:59 周五00:01~23:55',
        bjTime: '06:01~次日05:59 周五06:01~05:55'
      }, {
        name: 'EURUSD欧元兑美元',
        price1: '最高500',
        price2: '120欧元',
        contract: '10万单位基本货币',
        point: '10美元',
        platformTime: '00:01~23:59 周五00:01~23:55',
        bjTime: '06:01~次日05:59 周五06:01~05:55'
      }, {
        name: 'EURUSD欧元兑美元',
        price1: '最高500',
        price2: '120欧元',
        contract: '10万单位基本货币',
        point: '10美元',
        platformTime: '00:01~23:59 周五00:01~23:55',
        bjTime: '06:01~次日05:59 周五06:01~05:55'
      }, {
        name: 'EURUSD欧元兑美元',
        price1: '最高500',
        price2: '120欧元',
        contract: '10万单位基本货币',
        point: '10美元',
        platformTime: '00:01~23:59 周五00:01~23:55',
        bjTime: '06:01~次日05:59 周五06:01~05:55'
      }, {
        name: 'EURUSD欧元兑美元',
        price1: '最高500',
        price2: '120欧元',
        contract: '10万单位基本货币',
        point: '10美元',
        platformTime: '00:01~23:59 周五00:01~23:55',
        bjTime: '06:01~次日05:59 周五06:01~05:55'
      }]
    }
  }
}
</script>
<style lang="scss" scoped>
.warpper {
  width: 1350px;
  margin: 0 auto;
  background-color: transparent;
  box-shadow: 0px 0px 21px 0px rgba(201, 152, 89, 0.52);
  border-radius: 10px;
  border: solid 1px #132148;
  overflow: hidden;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

/deep/ .el-table--mini .el-table__cell {
  padding: 14px 0;
}

/deep/ .el-table th.el-table__cell {
  background-color: transparent;
}

/deep/ .el-table {
  color: #f1f1f1;
  font-size: 13px;
  font-weight: bold;
}
/deep/ .el-table::before {
  background-color: transparent !important;
}
/deep/ .el-table tr {
  background-color: transparent;
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: transparent;
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background-color: transparent;
}

/deep/ .el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #999;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .warpper {
    width: 95vw;
  }
}

@media screen and (max-width: 992px) {
  .warpper {
    width: 95vw;
  
  }

  .percentile-report__table {
    border-radius: 5px;
  }

 
  /deep/ .el-table {
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  /deep/ .el-table th.el-table__cell>.cell {
    font-size: 11px;
    font-weight: normal;
    line-height: 20px;
  }

  /deep/ .el-table td .cell,
  /deep/ .el-table th .cell {
    transform: scale(0.8);
    padding: 5px;
  }

  /deep/ .el-table--mini .el-table__cell {
    padding: 5px;
  }

  /deep/ .el-table .cell {
    padding: 5px;
  }

  /deep/ .el-table th.el-table__cell>.cell {
    padding: 5px;
  }
}
</style>