<template>
  <div class="chart" ref="chartRef" :style="chartStyle"></div>
</template>

<script>
import * as echarts from 'echarts'
import _ from 'lodash'
import { color, getDefaultChartOption } from '@/utils/chart'
export default {
  name: 'simple-gradient',

  props: {
    chartData: {
      type: Array,
      required: true
    },
    width: {
      type: [Number, String],
      default: 240
    },
    height: {
      type: [Number, String],
      default: 240,
      validator(val) {
        if (typeof val === 'number') return true

        return /^\d+%$/.text(val)
      }
    },
    propsOptions: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    chartData(value) {
      this.updateChart()
    }
  },

  data() {
    return {
      chartRef: 'chartRef',
      chart: null,
      option: null
    }
  },

  computed: {
    chartStyle() {
      return {
        width: !Number.isNaN(this.width) ? `${this.width}px` : this.width,
        height: !Number.isNaN(this.height) ? `${this.height}px` : this.height
      }
    }
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs[this.chartRef])
      this.mergeBaseChartOption()
    },

    mergeBaseChartOption() {
      this.option = _.merge(
        _.merge(getDefaultChartOption(), {
          grid: {
            left: 0,
            right: 0,
            top: 1,
            bottom: 1
          },
          yAxis: {
            axisLabel: {
              show: false
            },
            splitLine: { show: false }
          },
          xAxis: {
            show: false,
            axisLabel: {
              show: false
            },
            splitLine: { show: false }
          },
          axisPointer: { show: false },
          tooltip: { show: false },
          color: '#0D73D6'
        }),
        this.propsOptions
      )
    },

    updateChart() {
      this.xAxisData = this.chartData.map(d => d.time)
      this.dataSet = this.chartData.map(d => d.data)

      let datas = this.chartData.map(data => data.data)
      this.option.yAxis.max = Math.max.apply(null, datas)
      this.option.yAxis.min = Math.min.apply(null, datas)
      this.option.xAxis.data = this.xAxisData
      this.option.series = this.getSeries()

      this.chart.setOption(this.option)
    },

    getSeries() {
      return [
        {
          type: 'line',
          symbol: 'none',
          smooth: true,
          data: this.dataSet,
          lineStyle: {
            width: 2
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(13,115,214, 0.6)'
                },
                {
                  offset: 0.5,
                  color: 'rgba(13,115,214, 0.1)'
                },
                {
                  offset: 1,
                  color: 'transparent' // 100% 处的颜色
                }
              ]
            }
          }
        }
      ]
    }
  },

  mounted() {
    this.initChart()
    this.$nextTick(() => {
      this.updateChart()
    })
  }
}
</script>

<style scoped></style>
