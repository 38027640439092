<template>
  <div class="box">
    <div class="pic">
      <img src="@/assets/image/platform/device-MT5.png" alt="所有设备">
    </div>
    <div class="des">
      <h3>{{$t("platform.mt5.title1")}}</h3>
      <p class="info">{{$t("platform.mt5.describe1")}}</p>
      <div class="device">
        <img class="device-item" src="@/assets/image/platform/apple.png">
        <img class="device-item" src="@/assets/image/platform/android.png">
        <img class="device-item" src="@/assets/image/platform/win.png">
      </div>
       <button class="btn btn-primary"  @click="$open('pc5')">{{$t("platform.mt5.download3")}}</button>
    </div>
  </div>
</template>

<script>
export default {
  components:{},
  props:{},
  data(){
    return {
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped lang="scss">
.box{
  display: flex;
  padding: 120px 0;
  .pic, .des{
    flex: 1;
  }
  .pic{
    text-align: right;
    padding-right: 36px;
    img{
      // border: 1px dashed #000;
      display: inline-block;
    }
  }
  .des{
    padding-left: 36px;
    h3{
      height: 50px;
      font-size: 36px;
      font-weight: bold;
      color: #000000;
      line-height: 50px;
      margin-top: 20px;
    }
    .info{
      margin-top: 20px;
      font-size: 16px;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.6);
      line-height: 28px;
    }
    button{
      margin-top: 40px;
    }
    .device{
      display: flex;
      margin-top: 12px;
      .device-item{
        width: 36px;
        height: 36px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px 0px rgba(33,66,171,0.1500);
        border-radius: 2px;
        margin-right: 12px;
      }
    }
  }
}
</style>