<template>
    <div class="ban" :class="bg == '2' ? 'on' : ''">
        <div class="ban-bg"></div>
        <div class="tit">{{ computedTitle }}</div>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        bg: String
    },
    computed: {
        computedTitle() {
            return this.title;
        }
    }
}
</script>
<style scoped>
.ban {
    width: 100%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../assets/img/about/bg.jpg) no-repeat center center;
    background-size: 100% 100%;
    font-family: MicrosoftYaHeiUI-Bold;
    font-size: 50px;
    font-weight: bold;
    color: #ffffff;
    position: relative;
}
.ban-bg{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #132148;
    opacity: 0.8;
}
.tit{
    z-index: 3;
}

.ban.on {
    background: url(../../assets/img/about/ban2.png) no-repeat center center;
    background-size: 100% 100%;
}

@media screen and (max-width: 992px) {
    .ban {
        height: 220px;
        font-size: 25px;
    }
}
</style>