<template>
  <section class="disclaimer">
    <div class="container">
      <!-- <p>{{ $t("footer.address") }}</p>
      <p class="warning">
        {{ $t("footer.warning") }}：
        <br>
        {{ $t("footer.describe") }}
      </p> -->
      <p>{{ $t("newest.foot_tip1") }}</p>
      <p>{{ $t("newest.foot_tip2") }}</p>
      <p>{{ $t("newest.foot_tip3") }}</p>
      <p>{{ $t("newest.foot_tip4") }}</p>
      <p style="padding-top:20px;">{{ $t("newest.foot_tip5") }}</p>
      <p>{{ $t("newest.foot_tip6") }}</p>



    </div>
    <div class="copyright flex flex__center">
      {{ $t("newest.foot_tip7") }}

    </div>
  </section>
</template>

<script>
export default {
  name: 'disclaimer',
}
</script>

<style scoped lang="scss">
.disclaimer {
  margin-top: 52px;

  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
  }

  .warning {
    margin-top: 32px;
    color: rgba(255, 255, 255, .6);
  }

  .copyright {
    height: 56px;
    margin-top: 48px;
    background: rgba(255, 255, 255, 0.08);
  }
}

@media screen and(max-width: 992px) {
  .container {
    width: 100vw;
    padding: 20px 15px 0 15px;

  }

  .copyright {
    height: 36px !important;
  }

  .disclaimer {
    margin-top: 0;
  }

  .disclaimer p {
    font-size: 11px;
    line-height: 18px;
  }

  .disclaimer .warning {
    font-size: 12px;
    margin-top: 20px;
  }

  .disclaimer .copyright {
    font-size: 12px;
    margin-top: 20px;
  }
}
</style>
