<template>
  <div class="hottest-block flex-column flex__justify-space-between" @click="signIn">
    <h4 class="title">{{ apiCode }}</h4>
    <ul class="overview-list">
      <li class="overview-item flex-column">
        <span class="label">{{ $t("deal.forex.currencyRule.price") }}</span>
        <span class="value">{{ overviewData.price }}</span>
      </li>
      <li class="overview-item flex-column">
        <span class="label">{{ $t("deal.forex.currencyRule.day") }}</span>
        <span :class="['value', overviewData.dayType]">{{ overviewData.day }}</span>
      </li>
      <li class="overview-item flex-column">
        <span class="label">{{ $t("deal.forex.currencyRule.week") }}</span>
        <span :class="['value', overviewData.weekType]">{{ overviewData.week }}</span>
      </li>
    </ul>
    <div class="chart">
      <gradient-line-chart :width="screenWidth > 1200 ? 234 : '25%'" :height="58" :chart-data="chartData"
        :props-options="propsOptions"></gradient-line-chart>
    </div>
    <div class="footer-buttons flex flex__justify-flex-end">
      <!-- <button class="btn" @click="signIn">Buy</button>
      <button class="btn" @click="signIn">Sell</button> -->
    </div>
  </div>
</template>

<script>
import GradientLineChart from '@/components/deal/simple-gradient'
import { weekFirstOffsetTime, nowOffsetTime, opening } from '@/utils/dateUtils'
import { returnFloat } from '@/utils/common'
import { GbpService } from '@/service/gbp'
const gbpService = new GbpService()

export default {
  name: 'hottest-block',

  components: {
    GradientLineChart
  },

  props: {
    apiCode: {
      type: String,
      default: 'EURUSD'
    }
  },

  computed: {
    propsOptions() {
      return {
        grid: {
          left: -15,
          right: -15
        }
      }
    },
    screenWidth() {
      let width = localStorage.getItem('screen_width') || 1200
      return width
    },
  },

  watch: {},

  data() {
    return {
      timer: 0,
      symbolTimer: 0,
      chartData: [],
      overviewData: {
        price: '',
        dayStart: null,
        day: '',
        dayType: '',
        weekStart: null,
        week: '',
        weekType: ''
      }
    }
  },

  methods: {
    signIn() {
      if (localStorage.getItem('screen_width') > 992) {
        this.$router.push({ path: '/deal/kLine', query: { name: this.apiCode } })
      } else {
        this.$router.push({ path: '/phone/deal/kLine', query: { name: this.apiCode } })
      }

    },
    async getPriceBySymbol() {
      clearTimeout(this.symbolTimer)
      let _this = this
      let params = {
        symbol_array: [this.apiCode]
      }
      await gbpService.getPriceBySymbol(params).then(data => {
        data.forEach(d => {
          if (_this.overviewData.dayStart) {
            _this.overviewData.day =
              (Math.abs((d.BID - _this.overviewData.dayStart) / _this.overviewData.dayStart) * 100).toFixed(2) + '%'
            _this.overviewData.dayType = d.BID - _this.overviewData.dayStart > 0 ? 'increase' : 'decline'
          }
          if (_this.overviewData.weekStart) {
            _this.overviewData.week =
              (Math.abs((d.BID - _this.overviewData.weekStart) / _this.overviewData.weekStart) * 100).toFixed(2) + '%'
            _this.overviewData.weekType = d.BID - _this.overviewData.weekStart > 0 ? 'increase' : 'decline'
          }
          _this.overviewData.price = returnFloat(d.BID, this.apiCode)
        })

        _this.symbolTimer = setTimeout(() => {
          _this.getPriceBySymbol()
        }, 5000)
      })
    },
    async getChartData() {
      clearTimeout(this.timer)
      let nowTime = nowOffsetTime(10)

      let params = {
        reqtype: 'historyratesinfo',
        reqid: '100',
        symbol: this.apiCode,
        period: 5,
        fromtime: nowTime - 24 * 60 * 60,
        endtime: nowTime
      }

      let apiData = await gbpService.getHistoryRatesInfo(params)
      if (!this.overviewData.dayStart) {
        let today = new Date().getDate()
        let todayIndex = 0
        apiData.forEach((data, idx) => {
          if (!todayIndex && today === data.date) {
            todayIndex = idx
          }
        })
        const [{ data: dayStart }] = apiData.slice(todayIndex, todayIndex + 1)
        this.overviewData.dayStart = returnFloat(dayStart[0], this.apiCode)
      }

      this.chartData = apiData.map(data => {
        return {
          time: new Date(data.time).getTime(),
          data: data.data[1]
        }
      })
      opening() &&
        (this.timer = setTimeout(() => {
          this.getChartData()
        }, 120000))
    },

    async getWeekOpen() {
      let fromtime = weekFirstOffsetTime(-2) + 8 * 60 * 60 + 5 * 60 // 周一08:05开盘
      await gbpService
        .getHistoryRatesInfo({
          reqtype: 'historyratesinfo',
          reqid: '100',
          symbol: this.apiCode,
          period: 1,
          fromtime: fromtime,
          endtime: fromtime + 60
        })
        .then(res => {
          if (!res[0]) return
          const [{ data: weekStart }] = res.slice(0)
          this.overviewData.weekStart = returnFloat(weekStart[0], this.apiCode)
        })
    }
  },

  mounted() {
    this.getWeekOpen()
    this.getChartData()
    this.getPriceBySymbol()
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    if (this.symbolTimer) {
      clearTimeout(this.symbolTimer)
    }
  }
}
</script>

<style scoped lang="scss">
.hottest-block {
  width: 282px;
  height: 300px;
  padding: 24px;
  display: flex;
  background-color: #000;
  box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.3500);

  .title {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    margin-top: 6px;
  }

  .overview-list {
    display: flex;

    .overview-item {
      display: flex;
      flex-grow: 1;
      color: #fff;

      .label {
        font-size: 12px;
      }

      .value {
        font-size: 16px;
        margin-top: 10px;

        .increase {
          color: var(--info);
        }

        &.decline {
          color: var(--error);
        }
      }
    }
  }

  .footer-buttons {
    .btn {
      width: 67px;
      font-size: 14px;
      font-weight: 600;
      color: var(--text);
      background-color: #090806;

      &:last-child {
        margin-left: 8px;
      }

      &:hover {
        color: var(--theme-primary);
      }
    }
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px）*/
@media screen and (min-width: 1200px) {
  /* 当前网站以大屏幕方式设计开发 大于1200px 不作变动 */
}

/* 中等屏幕（桌面显示器，大于等于 992px 小于 1200px） */
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .hottest-block {
    width: calc(25% - 10px);
    margin: 0 5px;

    .footer-buttons {
      .btn {
        min-width: 90px;
      }
    }
  }
}

/* 小屏幕（平板，大于等于 768px 小于 992px） */
@media screen and (max-width: 992px) {
  .hottest-block {
    width: 95%;
    margin: 20px auto;
  }
}
</style>
