/**
 *
 * @returns 当前浏览器所在时区(负数是东区, 正数是西区)
 */
const timezone = () => {
  // getTimezoneOffset() 返回的是分钟数值
  let timedifference = new Date().getTimezoneOffset()
  return timedifference
}

/**
 *
 * @returns 当前格林威治时间 GMT0
 */
const gmtZoneTime = () => {
  // 已知本地时间，换算对应格林威治时间：
  // 格林威治时间 = 本地时间 + 时差
  return new Date().getTime() + timezone() * 60 * 1000
}
/**
 *
 * @param {number} offset 想获取的时区值 东区负数, 西区正数
 * @returns 指定时区当前时间
 */
const gmtOffsetTime = offset => {
  return gmtZoneTime() + offset * 60 * 60 * 1000
}

/**
 *
 * @param {number} offset 指定时区
 * @returns 周一的0点
 */
const weekFirstOffsetTime = offset => {
  let nowTemp = new Date(gmtOffsetTime(offset)) // 当前时间
  let oneDayLong = 24 * 60 * 60 * 1000 // 一天的毫秒数
  let c_time = nowTemp.getTime() // 当前时间的毫秒时间
  let c_day = nowTemp.getDay() || 7 // 当前时间的星期几
  let m_time = c_time - (c_day - 1) * oneDayLong // 当前周一的毫秒时间
  let monday = new Date(m_time) // 设置周一时间对象
  let m_year = monday.getFullYear()
  let m_month = monday.getMonth() + 1
  let m_date = monday.getDate()
  let Monday = m_year + '-' + m_month + '-' + m_date + ' ' + '00:00:01' // 周一的年月日
  return parseInt(new Date(Monday).getTime() / 1000)
}

/**
 * @todo 2是当前MT服务器设置时区, 将来修改此处修改变更
 * @returns 当前mt服务器为GMT2时区进行偏差修复, 防止GMT2到达0时, 开盘刷新不出最新行情
 */
const mtOffsetTime = () => {
  return 2 * 60 * 60 * 1000
}

/**
 *
 * @returns mt服务器是否开盘中, 用于判断是否需要定时刷新
 */
const opening = () => {
  let zoneTemp = new Date(gmtZoneTime() + mtOffsetTime())
  let z_day = zoneTemp.getDay() || 7
  return z_day < 6
}

/**
 *
 * @param {number} offset
 * @returns 获取某一时区当前时间, 如遇周末, 返回周五23:59:59
 */
const nowOffsetTime = offset => {
  let nowTemp = new Date(gmtOffsetTime(offset))
  let zoneTemp = new Date(gmtZoneTime() + mtOffsetTime())
  let z_day = zoneTemp.getDay() || 7
  if (z_day > 5) {
    let oneDayLong = 24 * 60 * 60 * 1000 // 一天的毫秒数
    nowTemp = zoneTemp - (z_day - 5) * oneDayLong // 当前周一的毫秒时间
    let friday = new Date(nowTemp)
    let m_year = friday.getFullYear()
    let m_month = friday.getMonth() + 1
    let m_date = friday.getDate()
    let Friday = m_year + '-' + m_month + '-' + m_date + ' ' + '23:59:59' // 周五的年月日
    return parseInt(new Date(Friday).getTime() / 1000)
  }
  return parseInt(nowTemp / 1000)
}

export { weekFirstOffsetTime, nowOffsetTime, opening }
