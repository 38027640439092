<template>
  <div class="box">
    <div class="des">
      <h3>{{ $t("platform.mt4.title4") }}</h3>
      <p class="info" v-html="describe"></p>
      <div class="download">
        <button class="btn btn-primary" @click="$open('iOS')">{{ $t("platform.mt4.download2") }}</button>
        <img src="@/assets/image/platform/QR-iOS.png" alt="iOS">
      </div>
      <div class="download">
        <button class="btn btn-primary" @click="$open('android')">{{ $t("platform.mt4.download1") }}</button>
        <img src="@/assets/image/platform/QR-android.png" alt="android">
      </div>
    </div>
    <div class="pic">
      <img src="@/assets/image/platform/mobile.png" alt="mac软件">
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  computed: {
    describe() {
      return this.$t("platform.mt4.describe4")
    }
  }
}
</script>
<style scoped lang="scss">
.box {
  display: flex;
  padding: 120px 0;
  // border: 1px dashed #000;
  @include bg-image('../../assets/image/platform/mobile-bg');

  .pic,
  .des {
    flex: 1;
  }

  .pic {
    padding-left: 36px;

    img {
      // border: 1px dashed #000;
      display: inline-block;
    }
  }

  .des {
    padding-right: 36px;
    text-align: right;

    h3 {
      height: 50px;
      font-size: 36px;
      font-weight: bold;
      color: #000000;
      line-height: 50px;
      margin-top: 50px;
    }

    .info {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.6);
      line-height: 28px;
    }

    .download {
      float: right;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: -70px;
      margin-left: 20px;

      button {
        margin-top: 40px;
      }

      img {
        width: 140px;
        margin-top: 20px;
      }
    }
  }
}
</style>