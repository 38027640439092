<template>
        <div class="forex-gpd-chart">
          <div class="box">
            <forex-gpd-chart :apiCode="apiCode"></forex-gpd-chart>
          </div>
        </div>
</template>

<script>
    import ForexGpdChart from "./forex-gpb-usd/forex-gpd-chart";
    export default {
        name: "view-line",
        components: {ForexGpdChart},
        data() {
            return {
                apiCode: 'USDJPY'
            }
        },
        created() {
            if(Object.keys(this.$route.query).length > 0) {
                this.apiCode = this.$route.query.name
            }
        },
    }
</script>

<style scoped lang="scss">
    .forex-gpd-chart{
        width: 100%;
        height: auto;
        overflow: hidden;

        .box{
            width: 1120px;
            height: auto;
            margin: 80px auto 40px;
        }
    }
    @media screen and (min-width: 992px) and(max-width: 1200px) {
        .forex-gpd-chart .box{
            width: 100vw;
        }
    }
    @media screen and(max-width: 992px) {
        .forex-gpd-chart .box{
            margin: 0 auto;
            width: 100vw;
        }
    }
</style>