<template>
  <div class="box">
    <div class="des">
      <h3>{{$t("platform.mt5.title2")}}</h3>
      <p class="info">{{$t("platform.mt5.describe2")}}</p>
       <button class="btn btn-primary"  @click="$open('mac5')">{{$t("platform.mt5.download4")}}</button>
    </div>
    <div class="pic">
      <img src="@/assets/image/platform/flexible-MT5.png" alt="订单灵活">
    </div>
  </div>
</template>

<script>
export default {
  components:{},
  props:{},
  data(){
    return {
    }
  }
}
</script>
<style scoped lang="scss">
.box{
  display: flex;
  padding: 120px 0;
  // border: 1px dashed #000;
  @include bg-image('../../assets/image/platform/mac-bg');
  .pic, .des{
    flex: 1;
  }
  .pic{
    padding-left: 36px;
    img{
      // border: 1px dashed #000;
      display: inline-block;
    }
  }
  .des{
    padding-right: 36px;
    text-align: right;
    h3{
      height: 50px;
      font-size: 36px;
      font-weight: bold;
      color: #000000;
      line-height: 50px;
      margin-top: 50px;
    }
    .info{
      margin-top: 20px;
      font-size: 16px;
      font-weight: 400;
      color: rgba($color: #000000, $alpha: 0.6);
      line-height: 28px;
    }
    button{
      margin-top: 40px;
    }
  }
}
</style>