<template>
    <div class="box">
        <CommunityBan :title="title"></CommunityBan>
        <div class="service">
            <div class="service_left">
                <img src="@/assets/img/community/up.png" class="up" />
                <h4>{{ $t("community.followingUp.title") }}</h4>
                <p>{{ $t("community.followingUp.service") }}
                </p>
                <div class="down">
                    <img src="@/assets/img/community/down.png" class="down-img" />
                </div>
            </div>
            <div class="service_right">
                <img src="@/assets/img/community/system.png" />
                <img src="@/assets/img/community/a.png" class="top-img" />
            </div>
        </div>
        <div class="buts">
            <button class="common-but but-now" @click="$open('signUp', $i18n.locale)">
                <img src="@/assets/img/community/user.png" />
                {{ $t("common.registerNow") }}
            </button>
            <button class="common-but but-now gray" @click="$open('signUp5', $i18n.locale)">
                <img src="@/assets/img/community/login.png" />
                {{ $t("common.registerNow5") }}
            </button>
        </div>
        <div class="tab">
            <ul>
                <li>
                    <p>
                        <span>01</span>
                        <img src="@/assets/img/community/1.png">
                    </p>
                    <div class="tit-word" v-html="$t('community.followingUp.tab1')"></div>
                </li>
                <li>
                    <p>
                        <span>02</span>
                        <img src="@/assets/img/community/2.png">
                    </p>
                    <div class="tit-word" v-html="$t('community.followingUp.tab2')"></div>
                </li>
                <li>
                    <p>
                        <span>03</span>
                        <img src="@/assets/img/community/3.png">
                    </p>
                    <div class="tit-word" v-html="$t('community.followingUp.tab3')"></div>
                </li>
                <li>
                    <p>
                        <span>04</span>
                        <img src="@/assets/img/community/4.png">
                    </p>
                    <div class="tit-word" v-html="$t('community.followingUp.tab4')"></div>
                </li>
                <li>
                    <p>
                        <span>05</span>
                        <img src="@/assets/img/community/5.png">
                    </p>
                    <div class="tit-word" v-html="$t('community.followingUp.tab5')"></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import CommunityBan from './CommunityBan.vue';
export default {
    name: 'view-following-up',
    components: {
        CommunityBan,
    },
    data() {
        return {
            // title: this.$t('header.followingUp')
        }
    },
    computed: {
        title() {
            return this.$t('header.followingUp');
        },
    }
}
</script>

<style lang="scss" src="./community.scss" scoped></style>