<template>
  <div class="fund-table">
    <div class="warpper">
      <div class="tabs">
        <div :class="['tab', curActive === item.num ? 'active' : '']" v-for="item in tabs" :key="item.num"
          @click="clickMe(item.num)">{{ item.name }}<div class="line"></div>
        </div>
      </div>
      <div class="table-box">
        <el-table :data="money" stripe>
          <el-table-column prop="name" align="center" :label="$t('support.fundTable.name')">

          </el-table-column>
          <el-table-column prop="type" align="center" :label="$t('support.fundTable.currency')">
          </el-table-column>
          <el-table-column prop="num" align="center" :label="$t('support.fundTable.gold')">
          </el-table-column>
          <el-table-column prop="itime" align="center" :label="$t('support.fundTable.itime')">
          </el-table-column>
          <el-table-column prop="ntime" align="center" :label="$t('support.fundTable.ntime')">
          </el-table-column>



          <!--          <el-table-column
            prop="money"
            align="center"
            :label="$t('support.fundTable.cost')">
          </el-table-column> -->
        </el-table>
      </div>
    </div>
    <!-- <div class="warpper fund-step" v-if="curActive === 1">
      <h3>{{ $t("support.fundInfo.goldIn") }}</h3>
      <h4>{{ $t("support.fundInfo.step1") }}</h4>
      <h4>{{ $t("support.fundInfo.step2") }}</h4>
      <h4>{{ $t("support.fundInfo.step3") }}</h4>
      <h4>{{ $t("support.fundInfo.step4") }}</h4>
      <h4>{{ $t("support.fundInfo.step5") }}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/fund-step5-en.png">
      <img v-else src="@/assets/image/support/fund-step5.png">
      <h4>{{ $t("support.fundInfo.step6") }}</h4>
      <h4>{{ $t("support.fundInfo.step7") }}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/fund-step7-en.png">
      <img v-else src="@/assets/image/support/fund-step7.png">
      <h4>{{ $t("support.fundInfo.step8") }}(<span>{{ $t("support.fundInfo.step8-1") }}</span>)</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/fund-step8-en.png">
      <img v-else src="@/assets/image/support/fund-step8.png">
      <h4>{{ $t("support.fundInfo.step9") }}(<span>{{ $t("support.fundInfo.step9-1") }}</span>)</h4>
    </div>
    <div class="warpper fund-step" v-if="curActive === 2">
      <h3>{{ $t("support.fundInfo.goldOut") }}</h3>
      <h4>{{ $t("support.fundInfo.out-step1") }}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/out-step1-en.png">
      <img v-else src="@/assets/image/support/out-step1.png">
      <h4>{{ $t("support.fundInfo.out-step2") }}</h4>
      <img v-if="$i18n.locale == 'en'" src="@/assets/image/support/out-step2-en.png">
      <img v-else src="@/assets/image/support/out-step2.png">
      <img v-if="$i18n.locale == 'zh'" src="@/assets/image/support/out-step2-1.png">
      <h4>{{ $t("support.fundInfo.out-step3") }}</h4>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      curActive: 1,
    }
  },
  computed: {
    tabs() {
      return [
        {
          name: this.$t('newAdd.tip26'),
          num: 1
        }, {
          name: this.$t('support.fundTable.withdrawals'),
          num: 2
        }
      ]
    },
    money() {
      return [
        {
          // src: require('@/assets/image/support/demo.png'),
          name: 'USDT',
          itime: "Instant",
          ntime: "1-3 days",
          num: '$50',
          type: 'USDT TRC20',
          money: `$500`
        },
        {
          // src: require('@/assets/image/support/demo.png'),
          time: this.$t('support.fundTable.workday2'),
          name: 'FastPay',
          itime: "Instant",
          ntime: "1-3 days",
          num: '$50',
          type: 'VND，INR',
          money: '$500'
        },
        {
          // src: require('@/assets/image/support/demo.png'),
          name: 'Bank Transfers',
          itime: "Instant",
          ntime: "1-3 days",
          num: '$50',
          type: 'AUD, CAD, CHF, EUR, GBP, HKD, JPY, NZD, SGD, USD',
          money: `$500`
        },
        {
          // src: require('@/assets/image/support/demo.png'),
          name: 'Credit/Debit Card',
          itime: "Instant",
          ntime: "1-3 days",
          num: '$50',
          type: 'AED, CAD, EUR, GBP, JPY, NZD, PLN, SGD, USD',
          money: `$500`
        },
        {
          // src: require('@/assets/image/support/demo.png'),
          itime: "Instant",
          ntime: "1-3 days",
          num: '$50',
          name: 'Thailand Internet Banking',
          type: 'THB',
          money: '$500'
        },
        {
          // src: require('@/assets/image/support/demo.png'),
          itime: "Instant",
          ntime: "1-3 days",
          name: 'Vietnamese Internet Banking	',
          num: '$50',
          type: 'VND',
          money: '$500'
        },
        {
          // src: require('@/assets/image/support/demo.png'),
          itime: "Instant",
          ntime: "1-3 days",
          name: 'Indonesian Internet Banking	',
          num: '$50',
          type: 'IDR',
          money: '$500'
        },
        {
          // src: require('@/assets/image/support/demo.png'),
          itime: "Instant",
          ntime: "1-3 days",
          name: 'Polish Internet Banking	',
          num: '$50',
          type: 'PLN',
          money: '$500'
        },
        {
          // src: require('@/assets/image/support/demo.png'),
          itime: "Instant",
          ntime: "1-3 days",
          name: 'Hong Kong Internet Banking',
          num: '$50',
          type: 'HKD',
          money: '$500'
        },
        {
          // src: require('@/assets/image/support/demo.png'),
          itime: "Instant",
          ntime: "1-3 days",
          name: 'Malaysian Internet Banking',
          num: '$50',
          type: 'MYR',
          money: '$500'
        },
        {
          // src: require('@/assets/image/support/demo.png'),
          itime: "Instant",
          ntime: "1-3 days",
          name: 'Philippines Internet Banking',
          num: '$50',
          type: 'PHP',
          money: '$500'
        },
        {
          // src: require('@/assets/image/support/demo.png'),
          itime: "Instant",
          ntime: "1-3 days",
          name: 'Singapore Internet Banking	',
          num: '$50',
          type: 'SGD',
          money: '$500'
        }

      ]
    }
  },
  methods: {
    clickMe(num) {
      this.curActive = num
    }
  }
}
</script>
<style scoped lang="scss">
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

/deep/ .el-table--mini .el-table__cell {
  padding: 14px 0;
}

.fund-table {

  .warpper {
    width: 1350px;
    margin: 0 auto;
  }

  .tabs {
    display: flex;
    justify-content: center;
    margin: 90px auto 60px auto;

    .tab {
      cursor: pointer;
      margin: 0 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 326px;
      height: 101px;
      line-height: 101px;
      background-color: #000000;
      box-shadow: 5px 8px 23px 4px rgba(201, 152, 89, 0.15);
      border-radius: 20px;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;

      .line {
        width: 48px;
        height: 6px;
        background: #000000;
        border-radius: 3px;
        display: none;
      }

      &.active {
        color: #000000;
        font-weight: bold;
        background-color: #132148;
        box-shadow: 5px 8px 23px 4px rgba(201, 152, 89, 0.15);

        .line {
          display: block;
        }
      }
    }
  }

  .table-box {
    width: 1350px;
    background-color: transparent;
    box-shadow: 0px 0px 21px 0px rgba(201, 152, 89, 0.52);
    border-radius: 10px;
    border: solid 1px #132148;
    overflow: hidden;
  }

  .fund-step {
    h3 {
      font-size: 28px;
      font-weight: bold;
      font-weight: 500;
      color: #333333;
      line-height: 33px;
      margin-top: 60px;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      margin: 20px 0;

      span {
        color: #FF0000;
      }
    }

    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

/deep/ .el-table th.el-table__cell {
  background-color: transparent;
}

/deep/ .el-table {
  color: #f1f1f1;
  font-size: 13px;
  font-weight: bold;
}
/deep/ .el-table::before {
  background-color: transparent !important;
}
/deep/ .el-table tr {
  background-color: transparent;
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: transparent;
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background-color: transparent;
}

@media screen and (min-width: 1200px) {}



@media screen and (max-width: 992px) {
  .fund-table {

    padding-top: 20px;

    .warpper {
      width: 95vw;
    }



    .table-box {
      width: 95vw;
    }

    .tabs {
      margin:0 auto 20px auto;
      .tab {
        font-size: 15px;
        height: 61px;
        line-height: 61px;
        .line {
          width: 24px;
          height: 3px;
        }

        &.active {
          font-size: 16px;
        }
      }
    }
  }

  /deep/ .el-table {
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }

  /deep/ .el-table th.el-table__cell>.cell {
    font-size: 11px;
    font-weight: normal;
    line-height: 20px;
  }

  /deep/ .el-table td .cell,
  /deep/ .el-table th .cell {
    transform: scale(0.7);
    padding: 0;
  }

  /deep/ .el-table--mini .el-table__cell {
    padding: 0;
  }

  /deep/ .el-table .cell {
    padding: 0;
  }
  /deep/ .el-table th.el-table__cell>.cell{
    padding: 0;
  }
}
</style>