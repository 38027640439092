<template>
<div>
 
  <div class="sub-page">
    <div class="bg-box"></div>
    <router-view></router-view>
  </div>
</div>
</template>

<script>
 
export default {
  name: 'Deal',
  components: {
 
  }
}
</script>

<style scoped lang="scss">
.sub-page {
  position: relative;
 
  /deep/ .el-breadcrumb__item {
    font-size: 16px;
  }
  /deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #000;
  }
  .bg-box{
    opacity: 0.2;
    position: absolute;
    left:0;
    top:0;
    width:100%;
    bottom:0;
    background: url(../assets/img/bg1.jpeg) no-repeat center center;
    background-size:100% 100%;
  }
}
@media screen and (max-width: 992px) {
  .sub-page{
    margin-top: 0;
    padding-top: 64px;
  }
}
</style>